import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../register.css';

const AdminRegister = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    surname: '',
    gender: '',
    telephone: '',
    fonction: '',
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};

    if (formData.password !== formData.confirmPassword) {
      errors.password = 'Les mots de passe ne correspondent pas';
      errors.confirmPassword = 'Les mots de passe ne correspondent pas';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        'https://ate69.fr:5000/api/admins',
        formData
      );
      if (response.status === 201) {
        console.log('Inscription réussie', response.data.message);

        await axios.post('https://ate69.fr:5000/api/send-confirmation-email', {
          email: formData.email,
          role: 'admin',
        });

        navigate('/ValidationWaitPage');
      } else {
        console.error(
          "Réponse inattendue lors de l'inscription:",
          response.status,
          response.data
        );
        alert("Réponse inattendue lors de l'inscription. Veuillez réessayer.");
      }
    } catch (error) {
      console.error(
        "Erreur lors de l'inscription:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Erreur lors de l'inscription: ${error.response ? error.response.data.message : 'Erreur de connexion au serveur.'}`
      );
    }
  };

  const nextStep = () => {
    if (validateFormStep(step)) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const validateFormStep = (step) => {
    const stepErrors = {};

    switch (step) {
      case 1:
        if (!formData.email) {
          stepErrors.email = 'Veuillez renseigner votre email.';
        }
        if (!formData.password) {
          stepErrors.password = 'Veuillez renseigner votre mot de passe.';
        }
        if (!formData.confirmPassword) {
          stepErrors.confirmPassword = 'Veuillez confirmer votre mot de passe.';
        }
        break;
      case 2:
        if (!formData.name) {
          stepErrors.name = 'Veuillez renseigner votre nom.';
        }
        if (!formData.surname) {
          stepErrors.surname = 'Veuillez renseigner votre prénom.';
        }
        if (!formData.gender) {
          stepErrors.gender = 'Veuillez sélectionner votre genre.';
        }
        if (!formData.telephone) {
          stepErrors.telephone = 'Veuillez renseigner votre téléphone.';
        }
        if (!formData.fonction) {
          stepErrors.fonction = 'Veuillez sélectionner une fonction.';
        }
        break;
      default:
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="step">
            <h2>Informations de connexion</h2>
            <div>
              <label htmlFor="email">Email :</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div>
              <label htmlFor="password">Mot de passe :</label>
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <div>
              <label htmlFor="confirmPassword">
                Confirmez le mot de passe :
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {errors.confirmPassword && (
                <span className="error">{errors.confirmPassword}</span>
              )}
            </div>
            <button type="button" onClick={nextStep}>
              Suivant
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="step">
            <h2>Informations personnelles</h2>
            <div>
              <label htmlFor="gender">Genre :</label>
              <select
                name="gender"
                id="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez votre genre</option>
                <option value="male">Mr</option>
                <option value="female">Mme</option>
              </select>
              {errors.gender && <span className="error">{errors.gender}</span>}
            </div>
            <div>
              <label htmlFor="name">Nom :</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div>
              <label htmlFor="surname">Prénom :</label>
              <input
                type="text"
                name="surname"
                id="surname"
                value={formData.surname}
                onChange={handleChange}
                required
              />
              {errors.surname && (
                <span className="error">{errors.surname}</span>
              )}
            </div>
            <div>
              <label htmlFor="telephone">Téléphone :</label>
              <input
                type="tel"
                name="telephone"
                id="telephone"
                value={formData.telephone}
                onChange={handleChange}
                required
              />
              {errors.telephone && (
                <span className="error">{errors.telephone}</span>
              )}
            </div>
            <div>
              <label htmlFor="fonction">Fonction :</label>
              <select
                name="fonction"
                id="fonction"
                value={formData.fonction}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez une fonction</option>
                <option value="exploitation">Exploitation</option>
                <option value="opération">Opération</option>
                <option value="administratif">Administratif</option>
                <option value="technique">Technique</option>
              </select>
              {errors.fonction && (
                <span className="error">{errors.fonction}</span>
              )}
            </div>
            <button type="button" onClick={prevStep}>
              Précédent
            </button>
            <button type="button" onClick={nextStep}>
              Suivant
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="step">
            <h2>Résumé et confirmation</h2>
            <div className="summary">
              <h3>Informations de connexion :</h3>
              <p>Email : {formData.email}</p>
              <p>Mot de passe : {formData.password.replace(/./g, '*')}</p>

              <h3>Informations personnelles :</h3>
              <p>Genre : {formData.gender === 'male' ? 'Mr' : 'Mme'}</p>
              <p>Nom : {formData.name}</p>
              <p>Prénom : {formData.surname}</p>
              <p>Téléphone : {formData.telephone}</p>
              <p>Fonction : {formData.fonction}</p>
            </div>
            <div className="buttons">
              <button type="button" onClick={prevStep}>
                Précédent
              </button>
              <button type="submit">Confirmer et s'inscrire</button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdminRegister;
