import React, { useState, useEffect } from 'react';
import axios from 'axios';

function FinishedDriverDetail({ driverId }) {
  const [convoyages, setConvoyages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDriverConvoyages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://ate69.fr:5003/api/convoyages/driver/${driverId}/finished`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setConvoyages(response.data);
      } catch (err) {
        setError(
          'Échec de la récupération des détails des convoyages terminés'
        );
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDriverConvoyages();
  }, [driverId]);

  // Fonction pour afficher une image
  const renderImage = (path) => {
    if (!path) return <p>Aucune image disponible</p>;
    return (
      <img
        src={`https://ate69.fr:5003/${path}`}
        alt="Détail du convoyage"
        style={{ width: '200px', margin: '10px' }}
      />
    );
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur : {error}</div>;
  if (!convoyages.length) return <div>Aucun détail de convoyage trouvé.</div>;

  return (
    <div>
      <h2>Détails des Convoyages (Terminés)</h2>
      {convoyages.map((convoyage, index) => (
        <div key={index}>
          {/* Afficher ici les informations de base du convoyage comme dans ClosedFormDetail */}
          <p>
            <strong>Client:</strong> {convoyage.clientDetails.name}{' '}
            {convoyage.clientDetails.surname}
          </p>
          {/* Afficher d'autres informations ici */}

          <h3>Photos de l'enlèvement</h3>
          {/* Afficher les photos d'enlèvement */}
          {renderImage(convoyage.pickupCheck.odoPhoto)}
          {renderImage(convoyage.pickupCheck.seatFrontLeftPhoto)}
          {/* Continuez avec les autres photos d'enlèvement */}

          <h3>Photos de la livraison</h3>
          {/* Afficher les photos de livraison */}
          {renderImage(convoyage.deliveryCheck.odoPhoto)}
          {renderImage(convoyage.deliveryCheck.seatFrontLeftPhoto)}
          {/* Continuez avec les autres photos de livraison */}

          {/* Si vous avez des photos dans les réserves, affichez-les ici */}
        </div>
      ))}
    </div>
  );
}

export default FinishedDriverDetail;
