import React from 'react';
import { Helmet } from 'react-helmet';
import CustomFooter from './Footer'; // Footer personnalisé
import CustomHeader from './Header_F'; // Header personnalisé

import '../css/Franchise.css'; // Le fichier CSS spécifique

const Franchise = () => {
  return (
    <>
      <Helmet>
        <title>Devenez franchisé avec ATEGROUP – Rejoignez un réseau innovant et performant</title>
        <meta name="description" content="Rejoignez le réseau ATEGROUP et profitez de l’innovation, du support, et d’une gestion automatisée avec un site moderne. Découvrez les avantages et devenez franchisé dès aujourd’hui." />
        <link rel="canonical" href="https://ate69.fr/franchise" />
      </Helmet>

      <div className="franchise-container">
        {/* Header principal */}
                <CustomHeader />


        {/* Section 1 */}
        <section className="franchise-section">
          <h2>Votre agence, soutenue par une supervision régionale</h2>
          <p>
            Chaque franchise ATEGROUP est rattachée à une agence régionale, garantissant un support local et personnalisé. 
            Cette structure permet une réactivité rapide aux besoins des franchisés et une supervision de proximité. Vous n'êtes jamais isolé, 
            le "siège" n'est jamais bien loin pour vous apporter l'accompagnement dont vous avez besoin.
          </p>
        </section>

        {/* Section 2 */}
        <section className="franchise-section">
          <h2>Un site web moderne et automatisé, conçu pour vous simplifier la vie</h2>
          <p>
            Nous mettons à votre disposition un site Internet de dernière génération, entièrement automatisé et autonome. 
            Il gère tout, des convoyages aux finances, en passant par les relations clients. Les mises à jour se font de manière centralisée, 
            et vous bénéficiez des dernières améliorations en temps réel, sans effort supplémentaire de votre part.
          </p>
        </section>

        {/* Section 3 */}
        <section className="franchise-section">
          <h2>Formation, suivi et accompagnement à chaque étape</h2>
          <p>
            Dès votre intégration dans le réseau ATEGROUP, vous bénéficiez d'une formation complète pour bien maîtriser tous les aspects de votre nouvelle franchise. 
            Mais nous ne nous arrêtons pas là : un suivi constant est assuré pour vous accompagner dans toutes les étapes de la gestion de votre activité. Vous n’êtes jamais seul.
          </p>
        </section>

        {/* Section 4 */}
        <section className="franchise-section">
          <h2>Un accès direct à l'expertise technique</h2>
          <p>
            En tant que franchisé, vous avez la possibilité de prendre rendez-vous avec le développeur du site. 
            Que ce soit pour des ajustements techniques ou pour discuter de nouvelles fonctionnalités, ce lien direct est un atout majeur dans l'optimisation de vos opérations.
          </p>
        </section>

        {/* Section 5 */}
        <section className="franchise-section">
          <h2>Livraisons conformes et traçabilité avec les carnets de CMR</h2>
          <p>
            Nous vous fournissons les carnets de CMR officiels pour garantir la conformité légale et la traçabilité des véhicules que vous livrez. 
            Ces documents sont indispensables pour opérer en toute sérénité et dans le respect des normes en vigueur.
          </p>
        </section>

        {/* Section 6 */}
        <section className="franchise-section">
          <h2>Un tarif compétitif et uniforme pour l'ensemble du réseau</h2>
          <p>
            En rejoignant le réseau ATEGROUP, vous bénéficiez d’un tarif fixe et compétitif, appliqué à l’échelle nationale. 
            Cela permet de maintenir une cohérence tarifaire et d’offrir une offre attractive pour tous vos clients, quelle que soit leur localisation.
          </p>
        </section>

        {/* Section 7 */}
        <section className="franchise-section">
          <h2>Un accès immédiat à une clientèle existante</h2>
          <p>
            Grâce à ATEGROUP, vous n'avez pas à partir de zéro. Dès le lancement de votre franchise, 
            vous avez accès à la base de clients existants du réseau, vous permettant de démarrer rapidement votre activité avec des clients B2B fiables et déjà en place.
          </p>
        </section>

        {/* Section 8 */}
        <section className="franchise-section">
          <h2>Connecté à un réseau de relais pour une logistique optimisée</h2>
          <p>
            Vous aurez accès à un réseau de relais logistique à travers la France, facilitant les échanges inter-agences et optimisant la gestion des convoyages. 
            Les relais ATEGROUP vous permettent de fluidifier vos opérations, qu'il s'agisse de convoyages ou de livraisons.
          </p>
        </section>

        {/* Section 9 */}
        <section className="franchise-section">
          <h2>Des communications professionnelles et uniformes</h2>
          <p>
            Nous vous fournissons des boîtes mails professionnelles, alignées à l’identité de la marque ATEGROUP, pour renforcer la communication avec vos clients et partenaires. 
            Ces outils garantissent une image cohérente et professionnelle à travers tout le réseau.
          </p>
        </section>

        {/* Section 10 */}
        <section className="franchise-section">
          <h2>Une présence active sur les réseaux sociaux</h2>
          <p>
            La visibilité de votre franchise est renforcée par une présence coordonnée et professionnelle sur les réseaux sociaux, gérée par ATEGROUP. 
            Des campagnes de marketing centralisées augmentent la visibilité de votre franchise et vous permettent d’atteindre de nouveaux clients potentiels.
          </p>
        </section>

        {/* Section 11 */}
        <section className="franchise-section">
          <h2>Matériel informatique complet pour une gestion optimale</h2>
          <p>
            Nous fournissons à chaque franchise un kit informatique complet, incluant un PC portable et une tablette, d'une valeur totale de 5 000 €. 
            Vous aurez tout le matériel nécessaire pour gérer efficacement votre activité.
          </p>
        </section>

        {/* Section 12 */}
        <section className="franchise-section">
          <h2>Un forfait de livraison et des mouvements de flotte attractifs</h2>
          <p>
            Nous proposons un service de livraison à domicile pour véhicules neufs et d'occasion à 149 € tout compris (jusqu'à 50 km), ainsi que des mouvements de flotte à 49 €. 
            Ces offres compétitives renforcent l’attractivité des services que vous pouvez proposer à vos clients.
          </p>
        </section>

        {/* Section 13 */}
        <section className="franchise-section">
          <h2>Serveurs, nom de domaine, et infrastructure fournis</h2>
          <p>
            Vous n'avez pas à vous soucier de la mise en place technique. Nous fournissons serveurs, nom de domaine et boîtes mails, 
            en plus d'une infrastructure digitale performante.
          </p>
        </section>

        {/* Section 14 */}
        <section className="franchise-section">
          <h2>Devenez franchisé avec ATEGROUP – Conditions requises</h2>
          <ul>
            <li>10 ans de permis ou 5 ans d'expérience avec FIMO/FCOS marchandises ou voyageurs.</li>
            <li>Assurance circulation convoyage et RC Pro.</li>
            <li>Fourniture d’un Kbis, d’un local/bureau professionnel, de smartphones et véhicules.</li>
            <li>Disposer d’équipements de bureau (bureau, chaise, etc.).</li>
            <li>Utilisation du logo standardisé d'ATEGROUP avec l’assistance de notre graphiste.</li>
            <li>Compte bancaire pro pour percevoir directement les paiements des clients B2B (règlements à 30 jours + le mois).</li>
          </ul>
        </section>

        {/* Section 15 */}
        <section className="franchise-section">
          <h2>Un soutien marketing pour vos opportunités futures</h2>
          <p>
            En plus de l'accès à notre base de clients, vous bénéficierez de campagnes de publicité centralisées et d'un support marketing local pour vous aider à développer votre propre clientèle régionale. 
            Vous aurez également la possibilité de participer à des partenariats locaux, renforçant l’implantation de votre franchise dans votre région.
          </p>
        </section>

        {/* Footer personnalisé */}
        <CustomFooter />
      </div>
    </>
  );
};

export default Franchise;
