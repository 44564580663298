import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../../pages/Header';
import Footer from '../../pages/Footer';
import '../../register.css';

const DriverRegister = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    surname: '',
    telephone: '',
    mobile: '',
    country: '',
    licenseYear: '',
    licenseNumber: '',
    rib: '',
    siret: '',
    personalAddress: {
      number: '',
      streetType: '',
      streetName: '',
      postalCode: '',
      city: '',
    },
    companyAddress: {
      number: '',
      streetType: '',
      streetName: '',
      postalCode: '',
      city: '',
    },
  });

  const [availableCountries, setAvailableCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAvailableCountries();
  }, []);

  const fetchAvailableCountries = async () => {
    try {
      const response = await axios.get(
        'https://ate69.fr:5002/api/drivers/available-countries'
      );
      setAvailableCountries(response.data);
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des pays disponibles',
        error
      );
    }
  };

  const checkEmailAvailability = async (email) => {
    try {
      const response = await axios.get(
        `https://ate69.fr:5002/api/drivers/check-email?email=${email}`
      );
      if (response.status === 200) {
        setIsEmailAvailable(true);
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setIsEmailAvailable(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Cet email est déjà utilisé',
        }));
      } else {
        console.error(
          "Erreur lors de la vérification de la disponibilité de l'email",
          error
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      checkEmailAvailability(value);
    }
    if (name.startsWith('personalAddress.')) {
      const addressField = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        personalAddress: {
          ...prev.personalAddress,
          [addressField]: value,
        },
      }));
    } else if (name.startsWith('companyAddress.')) {
      const addressField = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        companyAddress: {
          ...prev.companyAddress,
          [addressField]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (formData.password !== formData.confirmPassword) {
      errors.password = 'Les mots de passe ne correspondent pas';
      errors.confirmPassword = 'Les mots de passe ne correspondent pas';
    }

    if (!isEmailAvailable) {
      errors.email = 'Cet email est déjà utilisé';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        'https://ate69.fr:5002/api/drivers',
        formData
      );
      if (response.status === 201) {
        console.log('Inscription réussie', response.data.message);
        navigate('/ValidationWaitPage');
      } else {
        console.error(
          "Réponse inattendue lors de l'inscription:",
          response.status,
          response.data
        );
        alert("Réponse inattendue lors de l'inscription. Veuillez réessayer.");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert(
          "Conflit lors de l'inscription : Email ou ID personnalisé déjà utilisé."
        );
      } else {
        console.error(
          "Erreur lors de l'inscription:",
          error.response ? error.response.data : error.message
        );
        alert(
          `Erreur lors de l'inscription: ${error.response ? error.response.data.message : 'Erreur de connexion au serveur.'}`
        );
      }
    }
  };

  const nextStep = () => {
    if (validateFormStep(step)) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const validateFormStep = (step) => {
    const stepErrors = {};

    switch (step) {
      case 1:
        if (!formData.email) {
          stepErrors.email = 'Veuillez renseigner votre email.';
        } else if (!isEmailAvailable) {
          stepErrors.email = 'Cet email est déjà utilisé';
        }
        if (!formData.password) {
          stepErrors.password = 'Veuillez renseigner votre mot de passe.';
        }
        if (!formData.confirmPassword) {
          stepErrors.confirmPassword = 'Veuillez confirmer votre mot de passe.';
        }
        break;
      case 2:
        if (!formData.name) {
          stepErrors.name = 'Veuillez renseigner votre nom.';
        }
        if (!formData.surname) {
          stepErrors.surname = 'Veuillez renseigner votre prénom.';
        }
        if (!formData.telephone) {
          stepErrors.telephone = 'Veuillez renseigner votre téléphone.';
        }
        if (!formData.mobile) {
          stepErrors.mobile = 'Veuillez renseigner votre téléphone mobile.';
        }
        if (!formData.country) {
          stepErrors.country = 'Veuillez sélectionner votre pays.';
        }
        break;
      case 3:
        if (!formData.licenseYear) {
          stepErrors.licenseYear =
            "Veuillez renseigner l'année d'obtention du permis.";
        }
        if (!formData.licenseNumber) {
          stepErrors.licenseNumber = 'Veuillez renseigner le numéro de permis.';
        }
        if (!formData.rib) {
          stepErrors.rib = 'Veuillez renseigner votre RIB.';
        }
        if (!formData.siret) {
          stepErrors.siret = 'Veuillez renseigner votre SIRET.';
        }
        if (
          !formData.personalAddress.number ||
          !formData.personalAddress.streetType ||
          !formData.personalAddress.streetName ||
          !formData.personalAddress.postalCode ||
          !formData.personalAddress.city
        ) {
          stepErrors.personalAddress =
            'Veuillez renseigner votre adresse personnelle complète.';
        }
        if (
          !formData.companyAddress.number ||
          !formData.companyAddress.streetType ||
          !formData.companyAddress.streetName ||
          !formData.companyAddress.postalCode ||
          !formData.companyAddress.city
        ) {
          stepErrors.companyAddress =
            "Veuillez renseigner l'adresse complète de l'entreprise.";
        }
        break;
      default:
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  return (
    <div className="form-container">
      <Header />
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="step">
            <h2>Informations de connexion</h2>
            <div>
              <label htmlFor="email">Email :</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div>
              <label htmlFor="password">Mot de passe :</label>
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <div>
              <label htmlFor="confirmPassword">
                Confirmez le mot de passe :
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {errors.confirmPassword && (
                <span className="error">{errors.confirmPassword}</span>
              )}
            </div>
            <button type="button" onClick={nextStep}>
              Suivant
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="step">
            <h2>Informations personnelles</h2>
            <div>
              <label htmlFor="name">Nom :</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div>
              <label htmlFor="surname">Prénom :</label>
              <input
                type="text"
                name="surname"
                id="surname"
                value={formData.surname}
                onChange={handleChange}
                required
              />
              {errors.surname && (
                <span className="error">{errors.surname}</span>
              )}
            </div>
            <div>
              <label htmlFor="telephone">Téléphone :</label>
              <input
                type="tel"
                name="telephone"
                id="telephone"
                value={formData.telephone}
                onChange={handleChange}
                required
              />
              {errors.telephone && (
                <span className="error">{errors.telephone}</span>
              )}
            </div>
            <div>
              <label htmlFor="mobile">Téléphone mobile :</label>
              <input
                type="tel"
                name="mobile"
                id="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
              {errors.mobile && <span className="error">{errors.mobile}</span>}
            </div>
            <div>
              <label htmlFor="country">Pays :</label>
              <select
                name="country"
                id="country"
                value={formData.country}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez votre pays</option>
                {availableCountries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="error">{errors.country}</span>
              )}
            </div>
            <button type="button" onClick={prevStep}>
              Précédent
            </button>
            <button type="button" onClick={nextStep}>
              Suivant
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="step">
            <h2>Informations professionnelles</h2>
            <div>
              <label htmlFor="licenseYear">Année d'obtention du permis :</label>
              <input
                type="number"
                name="licenseYear"
                id="licenseYear"
                value={formData.licenseYear}
                onChange={handleChange}
                required
              />
              {errors.licenseYear && (
                <span className="error">{errors.licenseYear}</span>
              )}
            </div>
            <div>
              <label htmlFor="licenseNumber">Numéro de permis :</label>
              <input
                type="text"
                name="licenseNumber"
                id="licenseNumber"
                value={formData.licenseNumber}
                onChange={handleChange}
                required
              />
              {errors.licenseNumber && (
                <span className="error">{errors.licenseNumber}</span>
              )}
            </div>
            <div>
              <label htmlFor="rib">RIB :</label>
              <input
                type="text"
                name="rib"
                id="rib"
                value={formData.rib}
                onChange={handleChange}
                required
              />
              {errors.rib && <span className="error">{errors.rib}</span>}
            </div>
            <div>
              <label htmlFor="siret">SIRET :</label>
              <input
                type="text"
                name="siret"
                id="siret"
                value={formData.siret}
                onChange={handleChange}
                required
              />
              {errors.siret && <span className="error">{errors.siret}</span>}
            </div>
            <h3>Adresse personnelle</h3>
            <div>
              <label htmlFor="personalAddress.number">Numéro :</label>
              <input
                type="text"
                name="personalAddress.number"
                id="personalAddressNumber"
                value={formData.personalAddress.number}
                onChange={handleChange}
                required
              />
              {errors['personalAddress.number'] && (
                <span className="error">
                  {errors['personalAddress.number']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="personalAddress.streetType">Type de voie :</label>
              <input
                type="text"
                name="personalAddress.streetType"
                id="personalAddressStreetType"
                value={formData.personalAddress.streetType}
                onChange={handleChange}
                required
              />
              {errors['personalAddress.streetType'] && (
                <span className="error">
                  {errors['personalAddress.streetType']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="personalAddress.streetName">
                Nom de la rue :
              </label>
              <input
                type="text"
                name="personalAddress.streetName"
                id="personalAddressStreetName"
                value={formData.personalAddress.streetName}
                onChange={handleChange}
                required
              />
              {errors['personalAddress.streetName'] && (
                <span className="error">
                  {errors['personalAddress.streetName']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="personalAddress.postalCode">Code postal :</label>
              <input
                type="text"
                name="personalAddress.postalCode"
                id="personalAddressPostalCode"
                value={formData.personalAddress.postalCode}
                onChange={handleChange}
                required
              />
              {errors['personalAddress.postalCode'] && (
                <span className="error">
                  {errors['personalAddress.postalCode']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="personalAddress.city">Ville :</label>
              <input
                type="text"
                name="personalAddress.city"
                id="personalAddressCity"
                value={formData.personalAddress.city}
                onChange={handleChange}
                required
              />
              {errors['personalAddress.city'] && (
                <span className="error">{errors['personalAddress.city']}</span>
              )}
            </div>
            <h3>Adresse de l'entreprise</h3>
            <div>
              <label htmlFor="companyAddress.number">Numéro :</label>
              <input
                type="text"
                name="companyAddress.number"
                id="companyAddressNumber"
                value={formData.companyAddress.number}
                onChange={handleChange}
                required
              />
              {errors['companyAddress.number'] && (
                <span className="error">{errors['companyAddress.number']}</span>
              )}
            </div>
            <div>
              <label htmlFor="companyAddress.streetType">Type de voie :</label>
              <input
                type="text"
                name="companyAddress.streetType"
                id="companyAddressStreetType"
                value={formData.companyAddress.streetType}
                onChange={handleChange}
                required
              />
              {errors['companyAddress.streetType'] && (
                <span className="error">
                  {errors['companyAddress.streetType']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="companyAddress.streetName">Nom de la rue :</label>
              <input
                type="text"
                name="companyAddress.streetName"
                id="companyAddressStreetName"
                value={formData.companyAddress.streetName}
                onChange={handleChange}
                required
              />
              {errors['companyAddress.streetName'] && (
                <span className="error">
                  {errors['companyAddress.streetName']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="companyAddress.postalCode">Code postal :</label>
              <input
                type="text"
                name="companyAddress.postalCode"
                id="companyAddressPostalCode"
                value={formData.companyAddress.postalCode}
                onChange={handleChange}
                required
              />
              {errors['companyAddress.postalCode'] && (
                <span className="error">
                  {errors['companyAddress.postalCode']}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="companyAddress.city">Ville :</label>
              <input
                type="text"
                name="companyAddress.city"
                id="companyAddressCity"
                value={formData.companyAddress.city}
                onChange={handleChange}
                required
              />
              {errors['companyAddress.city'] && (
                <span className="error">{errors['companyAddress.city']}</span>
              )}
            </div>
            <button type="button" onClick={prevStep}>
              Précédent
            </button>
            <button type="submit">Confirmer et s'inscrire</button>
          </div>
        )}
      </form>
      <Footer />
    </div>
  );
};

export default DriverRegister;
