import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faClock, faBuilding } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/HeaderC.css';

const HeaderC = () => {
  return (
    <header className="bg-primary text-white text-center py-5 header-pro">
      <Helmet>
        <title>Inscription Client | ATE69</title>
        <meta name="description" content="Inscrivez-vous en tant qu'entreprise pour profiter des services de convoyage de véhicules par ATE69." />
      </Helmet>

      <div className="container">
        <div className="header-content d-flex flex-column align-items-center">
          {/* Main title */}
          <h1 className="display-4 fw-bold">Creez votre compte ATE69 pour profiter de nos services</h1>
          <p className="lead mt-3">Munissez-vous de votre SIRET et informations de facturation pour créer votre compte en moins d'une minute.</p>

          {/* Cards with icons */}
          <div className="d-flex justify-content-center mt-4">
            <div className="card-custom mx-3">
              <FontAwesomeIcon icon={faUserCheck} className="card-icon" />
              <h5 className="mt-3">Réservé aux Professionnels</h5>
              <p className="small">Un service exclusivement dédié aux entreprises.</p>
            </div>

            <div className="card-custom mx-3">
              <FontAwesomeIcon icon={faBuilding} className="card-icon" />
              <h5 className="mt-3">Inscriptions Rapides</h5>
              <p className="small">Munissez-vous de votre SIRET et créez un compte en quelques clics.</p>
            </div>

            <div className="card-custom mx-3">
              <FontAwesomeIcon icon={faClock} className="card-icon" />
              <h5 className="mt-3">Support Client</h5>
              <p className="small">Une assistance dédiée pour répondre à vos besoins professionnels.</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderC;
