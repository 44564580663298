import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

function UserDetailsModal({ isOpen, onClose, user, userType }) {
  const [modifiedUser, setModifiedUser] = useState({});

  useEffect(() => {
    setModifiedUser(user);
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModifiedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleAddressChange = (e, field) => {
    const value = e.target.value;
    setModifiedUser((prevUser) => ({
      ...prevUser,
      companyAddress: {
        ...prevUser.companyAddress,
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      let url;
      switch (userType) {
        case 'client':
          url = `https://ate69.fr:5001/api/clients/${modifiedUser._id}`;
          break;
        case 'admin':
          url = `https://ate69.fr:5000/api/admins/${modifiedUser._id}`;
          break;
        case 'driver':
          url = `https://ate69.fr:5002/api/drivers/${modifiedUser._id}`;
          break;
        default:
          throw new Error("Type d'utilisateur non reconnu");
      }

      const { _id, ...updateData } = modifiedUser;
      await axios.put(url, updateData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('Mise à jour réussie');
      onClose(); // Rafraîchir les données utilisateur dans le composant parent si nécessaire
    } catch (error) {
      console.error('Erreur lors de la mise à jour', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="User Details">
      <h2>Détails de l'Utilisateur</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        {/* Bloc Info Société */}
        <fieldset>
          <legend>Informations de l'entreprise</legend>
          <div>
            <label>Nom de la société :</label>
            <input
              type="text"
              name="companyName"
              value={modifiedUser.companyName || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Type de société :</label>
            <input
              type="text"
              name="companyType"
              value={modifiedUser.companyType || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>SIRET :</label>
            <input
              type="text"
              name="siret"
              value={modifiedUser.siret || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Type d'activité :</label>
            <input
              type="text"
              name="activityType"
              value={modifiedUser.companyType || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Capital :</label>
            <input
              type="text"
              name="capital"
              value={modifiedUser.capital || ''}
              onChange={handleChange}
            />
          </div>
        </fieldset>

        {/* Bloc Adresse */}
        <fieldset>
          <legend>Adresse de la société</legend>
          <div>
            <label>Numéro :</label>
            <input
              type="text"
              name="number"
              value={modifiedUser.companyAddress?.number || ''}
              onChange={(e) => handleAddressChange(e, 'number')}
            />
          </div>
          <div>
            <label>Type de rue :</label>
            <input
              type="text"
              name="streetType"
              value={modifiedUser.companyAddress?.streetType || ''}
              onChange={(e) => handleAddressChange(e, 'streetType')}
            />
          </div>
          <div>
            <label>Nom de la rue :</label>
            <input
              type="text"
              name="streetName"
              value={modifiedUser.companyAddress?.streetName || ''}
              onChange={(e) => handleAddressChange(e, 'streetName')}
            />
          </div>
          <div>
            <label>Code postal :</label>
            <input
              type="text"
              name="postalCode"
              value={modifiedUser.companyAddress?.postalCode || ''}
              onChange={(e) => handleAddressChange(e, 'postalCode')}
            />
          </div>
          <div>
            <label>Ville :</label>
            <input
              type="text"
              name="city"
              value={modifiedUser.companyAddress?.city || ''}
              onChange={(e) => handleAddressChange(e, 'city')}
            />
          </div>
        </fieldset>

        {/* Bloc Info Contact */}
        <fieldset>
          <legend>Informations de contact</legend>
          <div>
            <label>Nom :</label>
            <input
              type="text"
              name="name"
              value={modifiedUser.name || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Prénom :</label>
            <input
              type="text"
              name="surname"
              value={modifiedUser.surname || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Email :</label>
            <input
              type="email"
              name="email"
              value={modifiedUser.email || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Téléphone :</label>
            <input
              type="text"
              name="phone"
              value={modifiedUser.phone || ''}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Mobile :</label>
            <input
              type="text"
              name="mobile"
              value={modifiedUser.mobile || ''}
              onChange={handleChange}
            />
          </div>
        </fieldset>

        {/* Bloc Informations Financières */}
        <fieldset>
          <legend>Informations financières</legend>
          <div>
            <label>Encours Max :</label>
            <input
              type="number"
              name="encoursMax"
              value={modifiedUser.financialInfo?.encoursMax || ''}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: 'financialInfo.encoursMax',
                    value: e.target.value,
                  },
                })
              }
            />
          </div>
        </fieldset>

        {/* Bloc Horaires d'Ouverture */}
        <fieldset>
          <legend>Horaires d'ouverture</legend>
          {modifiedUser.openingHours?.map((hour, index) => (
            <div key={index}>
              <div>
                <label>Jour :</label>
                <input type="text" value={hour.day} readOnly />
              </div>
              <div>
                <label>Fermé :</label>
                <input type="checkbox" checked={hour.closed} readOnly />
              </div>
              <div>
                <label>Pause :</label>
                <input type="checkbox" checked={hour.hasBreak} readOnly />
              </div>
              {hour.times.map((slot, idx) => (
                <div key={idx}>
                  <label>De :</label>
                  <input type="text" value={slot.from} readOnly />
                  <label>À :</label>
                  <input type="text" value={slot.to} readOnly />
                </div>
              ))}
            </div>
          ))}
        </fieldset>

        <button type="button" onClick={handleSubmit}>
          Modifier
        </button>
        <button type="button" onClick={onClose}>
          Fermer
        </button>
      </form>
    </Modal>
  );
}

export default UserDetailsModal;
