import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCar, faCogs } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header'; // Import du header depuis le même dossier
import Footer from './Footer'; // Import du footer depuis le même dossier

const Register = () => {
  const navigate = useNavigate();

  // Contrôle des inscriptions
  const isClientOpen = true; // Inscription client ouverte
  const isDriverOpen = false; // Inscription driver fermée
  const isAdminOpen = false; // Inscription admin fermée

  return (
    <>
      {/* Header */}
      <Header />

      <div className="container mt-5">
        {/* Bloc d'information avec un fond bleu */}
        <div className="bg-primary text-center mb-4 p-4 text-white rounded">
          <h4 className="font-weight-bold">
            Les inscriptions clients sont{' '}
            {isClientOpen ? 'OUVERTES' : 'FERMÉES'} !
          </h4>
          <p>
            Les inscriptions drivers et admins ne sont pas encore disponibles.
            Restez connectés à nos réseaux sociaux pour ne pas manquer nos
            prochaines périodes de recrutement !
          </p>
          <hr className="bg-white" />
          <p className="mb-0">
            Pour toute question, n’hésitez pas à contacter notre service dédié.
          </p>
        </div>

        <h2 className="text-center mb-4">Choisissez votre rôle</h2>

        <div className="row text-center">
          {/* Section Client */}
          <div className="col-lg-4 col-md-6 mb-4">
            <button
              className={`btn btn-block ${!isClientOpen ? 'btn-secondary disabled' : 'btn-primary'}`}
              onClick={() => isClientOpen && navigate('/register/client')}
              disabled={!isClientOpen}
            >
              <FontAwesomeIcon icon={faUser} size="2x" className="mb-2" />
              <h5>Client</h5>
            </button>
          </div>

          {/* Section Driver */}
          <div className="col-lg-4 col-md-6 mb-4 position-relative">
            <button
              className={`btn btn-block ${!isDriverOpen ? 'btn-secondary disabled' : 'btn-primary'}`}
              onClick={() => isDriverOpen && navigate('/register/driver')}
              disabled={!isDriverOpen}
            >
              <FontAwesomeIcon icon={faCar} size="2x" className="mb-2" />
              <h5>Driver</h5>
            </button>
            {!isDriverOpen && <div className="closed-banner">CLOSED</div>}
          </div>

          {/* Section Admin */}
          <div className="col-lg-4 col-md-6 mb-4 position-relative">
            <button
              className={`btn btn-block ${!isAdminOpen ? 'btn-secondary disabled' : 'btn-primary'}`}
              onClick={() => isAdminOpen && navigate('/register/admin')}
              disabled={!isAdminOpen}
            >
              <FontAwesomeIcon icon={faCogs} size="2x" className="mb-2" />
              <h5>Admin</h5>
            </button>
            {!isAdminOpen && <div className="closed-banner">CLOSED</div>}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Register;
