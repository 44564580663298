import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Stats({ userId, role }) {
  const [stats, setStats] = useState({
    totalConvoyages: 0,
    totalKilometres: 0,
    convoyagesJunior: 0,
    convoyagesPremium: 0,
    convoyagesInfinite: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const baseUrl = `https://ate69.fr:500${role === 'admin' ? '0' : role === 'driver' ? '2' : '1'}`;
        const statsUrl = `${baseUrl}/api/stats/${userId}`;

        const response = await axios.get(statsUrl, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        setStats(response.data);
      } catch (error) {
        console.error(
          'Erreur lors de la r�cup�ration des statistiques:',
          error
        );
      }
    };

    fetchStats();
  }, [userId, role]);

  return (
    <div className="stats-container">
      <h3>Statistiques</h3>
      <p>
        <strong>Total des convoyages :</strong> {stats.totalConvoyages}
      </p>
      <p>
        <strong>Total des kilom�tres :</strong> {stats.totalKilometres} km
      </p>
      <p>
        <strong>Convoyages Juniors :</strong> {stats.convoyagesJunior}
      </p>
      <p>
        <strong>Convoyages Premium :</strong> {stats.convoyagesPremium}
      </p>
      <p>
        <strong>Convoyages Infinites :</strong> {stats.convoyagesInfinite}
      </p>
    </div>
  );
}

export default Stats;
