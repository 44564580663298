import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import QuelquesChiffres from './QuelquesChiffres';
import Partners from './Partners';
import '../centre-gestion.css'; // Utiliser les mêmes styles que CentreGestion
import '../Work.css'; // Utiliser les mêmes styles que Work

const Occasionnel = () => {
  return (
    <div>
      <Helmet>
        <title>Convoyages Occasionnels - ATE69</title>
        <meta
          name="description"
          content="Besoin d’un convoyage unique ou de quelques convoyages annuels ? Notre service de convoyage occasionnel est parfait pour vous. Profitez de la flexibilité de notre offre pour transporter vos véhicules en toute sécurité."
        />
        <link rel="canonical" href="https://ate69.fr/occasionnel" />
        <html lang="fr" />
      </Helmet>
      <Header />
      <section
        id="occasionnel"
        className="centre-gestion-section"
        itemScope
        itemType="http://schema.org/Service"
      >
        <div className="centre-gestion-container">
          <div className="centre-gestion-content">
            <h6>Convoyages Occasionnels</h6>
            <h3>Notre service de convoyage occasionnel</h3>
            <p itemProp="description">
              Besoin d’un convoyage unique ou de quelques convoyages annuels ?
              Notre service de convoyage occasionnel est parfait pour vous.
              Profitez de la flexibilité de notre offre pour transporter vos
              véhicules en toute sécurité.
            </p>
            <p>
              Que vous ayez besoin d’un convoyage unique ou de quelques
              convoyages annuels, notre service de convoyage occasionnel est
              conçu pour répondre à vos besoins spécifiques. Nous comprenons que
              chaque déplacement est unique et exige une attention particulière.
            </p>
            <p>
              Grâce à notre expertise et notre expérience, nous vous assurons
              une solution de transport fiable et sécurisée. Nos convoyeurs
              professionnels prennent en charge votre véhicule avec le plus
              grand soin, de la prise en charge à la livraison. Nous nous
              engageons à respecter les délais convenus et à vous fournir un
              service de qualité.
            </p>
            <p>
              Profitez de la flexibilité de notre offre pour transporter vos
              véhicules en toute tranquillité. Que ce soit pour un déplacement
              ponctuel ou pour quelques convoyages répartis sur l’année, nous
              nous adaptons à vos contraintes et à vos exigences.
            </p>
            <p>
              Notre service de convoyage occasionnel est parfait pour les
              particuliers et les professionnels souhaitant une solution de
              transport sur-mesure, sans les contraintes d’un engagement à long
              terme. Faites confiance à ATE69 pour tous vos besoins de convoyage
              automobile occasionnel.
            </p>

            <div className="centre-gestion-image" itemProp="image">
              <img
                src={require('../../src/img/img_card1.jpg')}
                alt="Image représentant le service de convoyage occasionnel"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="occasionnel-details" className="work-section">
        <div className="work-container">
          <h2>Convoyage Occasionnel - Les Points Essentiels</h2>
          <p id="sous-titre" itemProp="description">
            Notre service de convoyage occasionnel vous offre une flexibilité et
            une sécurité optimales pour tous vos besoins de transport de
            véhicules. Voici les points clés de notre offre.
          </p>
          <div className="work-row">
            <div className="work-card">
              <img
                src={require('../../src/img/generali.png')}
                alt="Assurance"
              />
              <h3>Assurance</h3>
              <p>
                Notre service de convoyage occasionnel inclut une assurance tout
                risque chez Generali, couvrant vos véhicules jusqu'à une valeur
                de 80 000€ H.T. Peu importe le nombre de convoyages, vous
                bénéficiez de la même couverture étendue, assurant ainsi une
                tranquillité d'esprit totale lors de chaque transport.
              </p>
            </div>
            <div className="work-card">
              <img src={require('../../src/img/vous-ici.jpg')} alt="Options" />
              <h3>Options</h3>
              <p>
                Nous offrons plusieurs options pour personnaliser votre
                convoyage : lavage extérieur pour une présentation impeccable,
                lavage intérieur pour un habitacle propre et accueillant, et
                livraison avec le plein pour que votre véhicule soit prêt à
                rouler dès réception. Chaque option est conçue pour améliorer
                votre expérience et répondre à vos besoins spécifiques.
              </p>
            </div>
            <div className="work-card">
              <img src={require('../../src/img/vous-ici.jpg')} alt="Paiement" />
              <h3>Paiement</h3>
              <p>
                Pour faciliter le processus, nous demandons un acompte de 50%
                avant l'enlèvement du véhicule. Le solde restant est dû à la
                livraison, garantissant une transparence et une sécurité
                financières tout au long de la transaction. Cette méthode assure
                une gestion efficace et sécurisée de chaque convoyage.
              </p>
            </div>
          </div>
        </div>
      </section>

      <QuelquesChiffres />
      <Partners />
      <Footer />
    </div>
  );
};

export default Occasionnel;
