import React, { useEffect, useRef } from 'react';

const Estimate = ({ origin, destination, vehicleType, onCalculate }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const scriptId = 'google-maps-script';

    if (!window.googleMapsScriptLoaded && !document.getElementById(scriptId)) {
      window.googleMapsScriptLoaded = true;

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        initMapAndServices();
      };

      script.onerror = () => {
        console.error('Échec du chargement du script Google Maps.');
        window.googleMapsScriptLoaded = false;
      };
    } else if (window.google && window.google.maps) {
      initMapAndServices();
    }

    function initMapAndServices() {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 45.76, lng: 4.85 },
        zoom: 6,
      });

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);

      calculateRoute(directionsService, directionsRenderer);
    }

    function calculateRoute(directionsService, directionsRenderer) {
      if (!origin || !destination) return;

      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(response);
            const distanceText = response.routes[0].legs[0].distance.text;
            const duration = response.routes[0].legs[0].duration.text;
            const distanceValue =
              response.routes[0].legs[0].distance.value / 1000; // Distance en kilomètres

            const serviceCost = calculateServiceCost(distanceValue);
            let fuelCost = 0;
            let tolls = 0;

            let isShortDistance = false;

            if (distanceValue > 150) {
              const fuelCostPerLiter = 1.99;
              let consumptionPer100km = 8;

              switch (vehicleType) {
                case 'citadine':
                  consumptionPer100km = 6;
                  break;
                case 'utilitaire':
                  consumptionPer100km = 10;
                  break;
                default:
                  break;
              }

              fuelCost =
                ((distanceValue * consumptionPer100km) / 100) *
                fuelCostPerLiter;
              tolls = distanceValue * 0.085;

              fuelCost = roundUpToFive(fuelCost);
              tolls = roundUpToFive(tolls);
            }

            const totalPrice = serviceCost + tolls + fuelCost;

            if (distanceValue <= 50) {
              isShortDistance = true;
            }

            onCalculate({
              serviceCost: serviceCost,
              tolls: tolls,
              fuelCost: fuelCost,
              totalPrice: totalPrice,
              distance: distanceText,
              duration: duration,
              isShortDistance: isShortDistance,
            });
          } else {
            console.error('Impossible de récupérer les directions', status);
          }
        }
      );
    }

    function roundUpToFive(value) {
      return Math.ceil(value / 5) * 5;
    }

    function calculateServiceCost(distanceInKm) {
      if (distanceInKm <= 50) {
        return 160; // Maintien du tarif à 160€ pour les distances jusqu'à 50 km
      } else if (distanceInKm <= 150) {
        return 170;
      } else if (distanceInKm <= 200) {
        return 180;
      } else if (distanceInKm <= 250) {
        return 190;
      } else if (distanceInKm <= 300) {
        return 200;
      } else if (distanceInKm <= 400) {
        return 245;
      } else if (distanceInKm <= 500) {
        return 285;
      } else if (distanceInKm <= 600) {
        return 330;
      } else if (distanceInKm <= 700) {
        return 370;
      } else if (distanceInKm <= 800) {
        return 420;
      } else if (distanceInKm <= 900) {
        return 460;
      } else if (distanceInKm <= 1000) {
        return 490;
      } else {
        const basePrice = 490;
        const additionalKm = distanceInKm - 999;
        const additionalPricePerKm = 0.48;
        const totalAmount = basePrice + additionalKm * additionalPricePerKm;
        return totalAmount.toFixed(2);
      }
    }
  }, [origin, destination, vehicleType]);

  return <div ref={mapRef} className="map-container"></div>;
};

export default Estimate;
