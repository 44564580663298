import React, { useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Estimate from '../components/Estimate';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRoad,
  faClock,
  faEuroSign,
  faRoadCircleCheck,
  faGasPump,
  faCalculator,
  faCar,
  faTruckPickup,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';

const Header = React.memo(({ isLoggedIn, userRole }) => {
  const [vehicleType, setVehicleType] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [estimateDetails, setEstimateDetails] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const originInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const navigate = useNavigate();

const loadGoogleMapsScript = useCallback(() => {
    const scriptId = 'google-maps-places-script';

    const initAutocomplete = () => {
        if (originInputRef.current) {
            const originAutocomplete = new window.google.maps.places.Autocomplete(
                originInputRef.current,
                { types: ['geocode', 'establishment'] }
            );
            originAutocomplete.addListener('place_changed', () => {
                const place = originAutocomplete.getPlace();
                setOrigin(place.formatted_address || place.name);
            });
        }

        if (destinationInputRef.current) {
            const destinationAutocomplete = new window.google.maps.places.Autocomplete(
                destinationInputRef.current,
                { types: ['geocode', 'establishment'] }
            );
            destinationAutocomplete.addListener('place_changed', () => {
                const place = destinationAutocomplete.getPlace();
                setDestination(place.formatted_address || place.name);
            });
        }
    };

    // Vérifie si le script est déjà chargé
    if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true; // Attribut async
        script.defer = true; // Attribut defer
        document.body.appendChild(script);

        script.onload = () => {
            window.googleMapsScriptLoaded = true;
            initAutocomplete(); // Initialise l'autocomplete après le chargement
        };

        script.onerror = () => {
            console.error('Échec du chargement du script Google Maps Places.');
        };
    } else if (window.google && window.google.maps && window.google.maps.places) {
        initAutocomplete(); // Réinitialise si la librairie est déjà présente
    }
}, []);


  const handleEstimate = useCallback((details) => {
    setEstimateDetails(details);
  }, []);

  const handleShowAlert = useCallback(
    (totalPrice) => {
      if (!showAlert) {
        alert(
          `Pour les trajets de moins de 50 km, nous proposons une livraison à domicile pour 149€ tout compris pour les clients réguliers. Saviez-vous que vous pouvez économiser ${totalPrice - 149}€ avec notre formule L.A.D ? Contactez-nous pour plus d'informations !`
        );
        setShowAlert(true);
      }
    },
    [showAlert]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!origin || !destination || !vehicleType) {
        alert('Veuillez remplir tous les champs.');
      } else {
        handleEstimate({ vehicleType, origin, destination });
      }
    },
    [origin, destination, vehicleType, handleEstimate]
  );

  const handleBack = useCallback(() => {
    setEstimateDetails(null);
    setVehicleType('');
    setOrigin('');
    setDestination('');
    setShowAlert(false);
  }, []);

  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleRegister = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  const handleDashboard = useCallback(() => {
    navigate('/client/dashboard');
  }, [navigate]);

  const titleAnimation = {
    initial: { y: -20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { type: 'spring', stiffness: 120, delay: 0.2 },
  };

  const subtitleAnimation = {
    initial: { y: -20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { type: 'spring', stiffness: 120, delay: 0.4 },
  };

  return (
    <header
      className="header"
      itemScope
      itemType="http://schema.org/Organization"
    >
      <Helmet>
        <title>
          Auto Transport Express - Spécialiste du Convoyage Automobile Léger
        </title>
        <meta
          name="description"
          content="Auto Transport Express offre des services de convoyage automobile léger de haute qualité en France et à l'international. Découvrez nos solutions sur mesure pour tous vos besoins de transport."
        />
        <meta
          name="keywords"
          content="convoyage automobile, transport léger, transport international, transport France"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
          as="style"
          onLoad="this.onload=null;this.rel='stylesheet'"
        />
      </Helmet>

      <div
        className="container text-center py-5"
        itemProp="name"
        style={{
          backgroundColor: 'rgba(49, 186, 240, 0.5)',
          zIndex: '-1',
          padding: '50px 20px',
        }}
      >
        <motion.div
          initial="initial"
          animate="animate"
          variants={titleAnimation}
          className="text-container"
        >
          <motion.h1
            className="main-heading display-4"
            itemProp="headline"
            style={{ color: '#000' }}
          >
            AUTO TRANSPORT EXPRESS
          </motion.h1>
          <motion.h2
            className="subtitle h3"
            itemProp="description"
            variants={subtitleAnimation}
            style={{ color: '#000' }}
          >
            Votre Solution de Convoyage Automobile Léger en France et à
            l’International
          </motion.h2>
        </motion.div>

        {!estimateDetails ? (
          <>
            <form className="estimate-form" onSubmit={handleSubmit}>
              <motion.div
                className="estimate-intro"
                style={{ color: '#000' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <h4>Estimez Votre Convoyage en Quelques Clics !</h4>
                <h5>
                  Obtenez une estimation rapide et précise pour le convoyage de
                  votre véhicule en quelques étapes simples.
                </h5>
              </motion.div>

              <h6 className="text-dark">Type de véhicule</h6>
              <div className="d-flex justify-content-center">
                <div
                  className={`card m-2 ${vehicleType === 'citadine' ? 'border-primary' : ''}`}
                  onClick={() => setVehicleType('citadine')}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faCar}
                      className="text-dark fs-1"
                      title="Citadine"
                    />
                    <h5 className="card-title">Citadine</h5>
                  </div>
                </div>
                <div
                  className={`card m-2 ${vehicleType === 'berline' ? 'border-primary' : ''}`}
                  onClick={() => setVehicleType('berline')}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faTruckPickup}
                      className="text-dark fs-1"
                      title="Berline"
                    />
                    <h5 className="card-title">Berline</h5>
                  </div>
                </div>
                <div
                  className={`card m-2 ${vehicleType === 'utilitaire' ? 'border-primary' : ''}`}
                  onClick={() => setVehicleType('utilitaire')}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="card-body text-center">
                    <FontAwesomeIcon
                      icon={faTruck}
                      className="text-dark fs-1"
                      title="Utilitaire"
                    />
                    <h5 className="card-title">Utilitaire</h5>
                  </div>
                </div>
              </div>

              <input
                type="text"
                className="form-control mt-3"
                placeholder="Adresse enlèvement"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                ref={originInputRef}
                onFocus={loadGoogleMapsScript}
                required
              />
              <input
                type="text"
                className="form-control mt-3"
                placeholder="Adresse livraison"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                ref={destinationInputRef}
                onFocus={loadGoogleMapsScript}
                required
              />
              <button type="submit" className="btn btn-primary mt-4">
                Estimer
              </button>
            </form>
          </>
        ) : (
          <div className="estimate-display mt-4 d-flex justify-content-center">
            <Estimate
              origin={origin}
              destination={destination}
              vehicleType={vehicleType}
              onCalculate={handleEstimate}
              onShowAlert={handleShowAlert}
            />
            <div
              className="estimate-results bg-white p-4 shadow-sm mt-4 rounded text-left"
              style={{ width: '50%' }}
            >
              <h3 className="estimate-title">Estimation du convoyage</h3>
              <p>
                <strong>Adresse de départ :</strong> {origin}
              </p>
              <p>
                <strong>Adresse d'arrivée :</strong> {destination}
              </p>
              <p>
                <strong>Type de véhicule :</strong> {vehicleType.toUpperCase()}
              </p>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <FontAwesomeIcon icon={faRoad} className="fs-2" />
                <span>Distance :</span>
                <span>{estimateDetails.distance}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <FontAwesomeIcon icon={faClock} className="fs-2" />
                <span>Durée :</span>
                <span>{estimateDetails.duration}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <FontAwesomeIcon icon={faEuroSign} className="fs-2" />
                <span>Tarif :</span>
                <span>{estimateDetails.serviceCost} €</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <FontAwesomeIcon icon={faRoadCircleCheck} className="fs-2" />
                <span>Péage :</span>
                <span>{estimateDetails.tolls} €</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <FontAwesomeIcon icon={faGasPump} className="fs-2" />
                <span>Carburant :</span>
                <span>{estimateDetails.fuelCost} €</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <FontAwesomeIcon icon={faCalculator} className="fs-2" />
                <span>Total estimé :</span>
                <span>{estimateDetails.totalPrice} €</span>
              </div>

              {/* Boutons en fonction de l'état de connexion */}
              {isLoggedIn && userRole === 'client' ? (
                <button
                  className="btn btn-success mt-4"
                  onClick={handleDashboard}
                >
                  Aller au Dashboard
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-outline-primary mt-4 me-2"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                  <button
                    className="btn btn-outline-secondary mt-4"
                    onClick={handleRegister}
                  >
                    Register
                  </button>
                </>
              )}

              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleBack}
              >
                Retour
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
});

Header.displayName = 'Header';

export default Header;
