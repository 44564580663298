import React, { useState } from 'react';
import axios from 'axios';
import Estimate from '../../components/Estimate';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/ConvoyageForm.css';

function LAD_Form({ clientInfo }) {
  const generateCustomIdF = () => {
    const prefix = 'ATE69|LAD|';
    const uniqueId = Math.random().toString(36).substr(2, 9).toUpperCase();
    return `${prefix}${uniqueId}`;
  };

  const initialState = {
    clientDetails: {
      customIdC: clientInfo?.customIdC || '',
      siret: clientInfo?.siret || '',
      name: clientInfo?.name || '',
      surname: clientInfo?.surname || '',
      email: clientInfo?.email || '',
      phone: clientInfo?.phone || '',
      company: clientInfo?.companyName || '',
      address: clientInfo?.companyAddress || {
        streetType: '',
        streetName: '',
        number: '',
        city: '',
        postalCode: '',
      },
    },
    vehicleDetails: {
      type: 'L.A.D', // Défini pour LAD
      brand: '',
      model: '',
      registration: '',
      availabilityDate: new Date(),
    },
    pickupDetails: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      company: '',
      address: {
        streetType: '',
        streetName: '',
        number: '',
        city: '',
        postalCode: '',
      },
    },
    deliveryDetails: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      company: '',
      address: {
        streetType: '',
        streetName: '',
        number: '',
        city: '',
        postalCode: '',
      },
    },
    pricingDetails: {
      serviceCost: 149, // Forfait LAD
      tollCostEstimate: 0, // Péage à zéro
      fuelCostEstimate: 0, // Carburant à zéro
      totalPrice: 149,
      distance: 0,
      duration: '00:00',
      advancePending: 149 / 2,
      finalAmountDue: 149 / 2,
      isShortDistance: true,
    },
    role: '',
    customIdF: generateCustomIdF(),
  };

  const [formData, setFormData] = useState(initialState);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [acceptedCGV, setAcceptedCGV] = useState(false);

  const handleNestedChange = (e, section) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [name]: value,
      },
    }));
  };

  const handleAddressChange = (e, section) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        address: {
          ...prevFormData[section].address,
          [name]: value,
        },
      },
    }));
  };

  const handleRoleChange = (role) => {
    const updatedFormData = { ...formData, role };
    if (role === 'expéditeur') {
      updatedFormData.pickupDetails = { ...clientInfo, address: clientInfo.companyAddress };
    } else {
      updatedFormData.deliveryDetails = { ...clientInfo, address: clientInfo.companyAddress };
    }
    setFormData(updatedFormData);
    setStep(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedCGV) {
      setErrors({ cgv: 'Vous devez accepter les Conditions Générales de Vente.' });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post('https://ate69.fr:5003/api/convoyages', formData, config);
      alert('Votre demande de livraison à domicile a bien été envoyée.');
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire:", error.response ? error.response.data : error.message);
    }
  };

  const formatAddress = (address) => {
    if (!address) return 'Adresse non disponible';
    const { streetType, streetName, number, city, postalCode } = address;
    return `${number} ${streetType} ${streetName}, ${postalCode} ${city}`;
  };

  const goToNextStep = () => setStep(step + 1);
  const goToPreviousStep = () => setStep(step - 1);

  return (
    <form onSubmit={handleSubmit}>
      {step === 1 && (
        <div className="card p-4 mb-4">
          <label>Je suis :</label>
          <div className="d-flex justify-content-around">
            <div className={`card p-3 text-center ${formData.role === 'expéditeur' ? 'bg-primary text-white' : ''}`} onClick={() => handleRoleChange('expéditeur')}>
              <FontAwesomeIcon icon={faUserTie} size="2x" />
              <p>Expéditeur</p>
            </div>
            <div className={`card p-3 text-center ${formData.role === 'destinataire' ? 'bg-primary text-white' : ''}`} onClick={() => handleRoleChange('destinataire')}>
              <FontAwesomeIcon icon={faUser} size="2x" />
              <p>Destinataire</p>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="card p-4 mb-4">
          <label>
            Date de disponibilité <span className="text-danger">*</span>
          </label>
          <div className="calendar-container">
            <DatePicker selected={formData.vehicleDetails.availabilityDate} onChange={(date) => handleNestedChange({ target: { name: 'availabilityDate', value: date } }, 'vehicleDetails')} dateFormat="dd/MM/yyyy" minDate={new Date()} inline required />
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="card p-4 mb-4">
          <h3>Adresse de {formData.role === 'destinataire' ? "l'expéditeur" : 'du destinataire'}</h3>
          <label>Numéro <span className="text-danger">*</span></label>
          <input type="text" name="number" className={`form-control ${errors.number ? 'is-invalid' : ''}`} value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.number} onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')} required />
          <label className="mt-3">Type de voie <span className="text-danger">*</span></label>
          <input type="text" name="streetType" className={`form-control ${errors.streetType ? 'is-invalid' : ''}`} value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.streetType} onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')} required />
          <label className="mt-3">Nom de rue <span className="text-danger">*</span></label>
          <input type="text" name="streetName" className={`form-control ${errors.streetName ? 'is-invalid' : ''}`} value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.streetName} onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')} required />
          <label className="mt-3">Code postal <span className="text-danger">*</span></label>
          <input type="text" name="postalCode" className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`} value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.postalCode} onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')} required />
          <label className="mt-3">Ville <span className="text-danger">*</span></label>
          <input type="text" name="city" className={`form-control ${errors.city ? 'is-invalid' : ''}`} value={formData[formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails'].address.city} onChange={(e) => handleAddressChange(e, formData.role === 'destinataire' ? 'pickupDetails' : 'deliveryDetails')} required />
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
          </div>
        </div>
      )}

      {step === 4 && (
  <div className="card p-4 mb-4">
    <h3>Détails de tarification</h3>
    <p>Tarif fixe: 149 €</p>
    <div className="d-flex justify-content-between mt-4">
      <button type="button" className="btn btn-secondary" onClick={goToPreviousStep}>Précédent</button>
      <button type="button" className="btn btn-primary" onClick={goToNextStep}>Suivant</button>
    </div>
  </div>
)}


      {step === 5 && (
        <div className="card p-4 mb-4">
          <h3>Résumé de la livraison à domicile</h3>
          <div className="row">
            <div className="col-md-6">
              <p><strong>Type:</strong> L.A.D</p>
              <p><strong>Prix fixe:</strong> 149 €</p>
              <p><strong>Acompte:</strong> 74,50 €</p>
            </div>
          </div>
          <div className="form-group mt-4">
            <input type="checkbox" id="acceptCGV" checked={acceptedCGV} onChange={(e) => setAcceptedCGV(e.target.checked)} required />
            <label htmlFor="acceptCGV" className="ml-2">J'accepte les <a href="https://ate69.fr/cgv" target="_blank" rel="noopener noreferrer">Conditions Générales de Vente</a></label>
            {errors.cgv && <div className="text-danger mt-2">{errors.cgv}</div>}
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button type="button" className="btn btn-secondary" onClick={goToPreviousStep}>Précédent</button>
            <button type="submit" className="btn btn-success">Soumettre</button>
          </div>
        </div>
      )}
    </form>
  );
}

export default LAD_Form;
