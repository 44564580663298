import React, { useEffect, useRef } from 'react';

const Map = ({ origin, destination }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const scriptId = 'google-maps-places-script';
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (!apiKey) {
      console.error('Google Maps API key is missing');
      return;
    }

    const loadScript = () => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
          initMap();
        };

        script.onerror = () => {
          console.error('Failed to load the Google Maps script.');
        };
      } else if (window.google && window.google.maps) {
        // Script already loaded
        initMap();
      }
    };

    const initMap = () => {
      if (!window.google) {
        console.error("Google Maps API hasn't been loaded yet.");
        return;
      }

      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 48.8566, lng: 2.3522 }, // Coordonnées de Paris par défaut
        zoom: 7,
      });

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();

      directionsRenderer.setMap(map);

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error(`Error fetching directions: ${status}`);
          }
        }
      );
    };

    // Charger le script uniquement si Google Maps n'est pas déjà présent
    if (!window.google || !window.google.maps) {
      loadScript();
    } else {
      initMap(); // Si Google Maps est déjà chargé
    }
  }, [origin, destination]);

  return <div ref={mapRef} style={{ width: '100%', height: '250px' }} />;
};

export default Map;
