import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FormCard from '../../components/ClientForms/FormCard';
import axios from 'axios';

function DriverDashboard() {
    const navigate = useNavigate();
    const [driverInfo, setDriverInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [missions, setMissions] = useState([]);
    const [statistics, setStatistics] = useState({
        totalMissions: 0,
        totalKilometers: 0,
        totalVehicles: 0,
        averageDeliveryTime: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState('');

    const validatedRef = useRef(null);
    const startedRef = useRef(null);
    const terminatedRef = useRef(null);
    const finishedRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const customIdD = user.customIdD;

        if (!token || !customIdD) {
            navigate('/login');
            return;
        }

        let isMounted = true;

        const fetchDriverData = async () => {
            try {
                const headers = { Authorization: `Bearer ${token}` };
                const [driverResponse, missionsResponse] = await Promise.all([
                    axios.get(`https://ate69.fr:5002/api/drivers/${customIdD}`, { headers }),
                    axios.get(`https://ate69.fr:5003/api/convoyages/driver/${customIdD}`, { headers }),
                ]);

                const driverData = driverResponse.data;
                const assignedMissions = missionsResponse.data.filter(
                    (mission) => mission.assignedDriver?.customIdD === customIdD
                );

                // Calcul des statistiques
                const totalKilometers = assignedMissions.reduce(
                    (sum, mission) => sum + (mission.kilometers || 0),
                    0
                );
                const totalVehicles = assignedMissions.reduce(
                    (sum, mission) => sum + (mission.vehicles?.length || 0),
                    0
                );
                const totalDeliveryTime = assignedMissions.reduce(
                    (sum, mission) => sum + (mission.deliveryTime || 0),
                    0
                );
                const averageDeliveryTime =
                    assignedMissions.length > 0
                        ? totalDeliveryTime / assignedMissions.length
                        : 0;

                if (isMounted) {
                    setDriverInfo(driverData);
                    setMissions(assignedMissions);
                    setStatistics({
                        totalMissions: assignedMissions.length,
                        totalKilometers,
                        totalVehicles,
                        averageDeliveryTime: averageDeliveryTime.toFixed(2),
                    });
                    setLoading(false);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des informations ou des missions:', error);
                navigate('/login');
            }
        };

        fetchDriverData();

        return () => {
            isMounted = false;
        };
    }, [navigate]);

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const handleSearch = async () => {
        if (searchTerm.trim() === '') {
            setError('Veuillez entrer un terme de recherche.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('Token non fourni.');

            const response = await axios.get(`https://ate69.fr:5003/api/convoyages/search/${searchTerm}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setSearchResults(response.data);
            setError('');
        } catch (error) {
            console.error('Erreur lors de la recherche des missions:', error);
            setError('Erreur lors de la recherche des missions.');
        }
    };

    const filterMissionsByStatus = (status) =>
        missions.filter((mission) => mission.status === status);

    if (loading) {
        return <div>Chargement des informations...</div>;
    }

    const { totalMissions, totalKilometers, totalVehicles, averageDeliveryTime } =
        statistics;

    return (
        <div>
            <h1>Tableau de Bord du Conducteur ATE69</h1>
            {driverInfo && (
                <>
                    {/* Informations Conducteur */}
                    <div className="dashboard-container">
                        <div className="driver-info-card">
                            <h2>Informations Conducteur</h2>
                            <div className="info-item">
                                <span>Nom:</span>
                                <span>{driverInfo.name}</span>
                            </div>
                            <div className="info-item">
                                <span>Email:</span>
                                <span>{driverInfo.email}</span>
                            </div>
                            <div className="info-item">
                                <span>Téléphone:</span>
                                <span>{driverInfo.mobile || 'Non disponible'}</span>
                            </div>
                            <div className="info-item">
                                <span>ID Conducteur:</span>
                                <span>{driverInfo.customIdD}</span>
                            </div>
                            <div className="info-item">
                                <span>Rang:</span>
                                <span>{driverInfo.rank || 'Non classé'}</span>
                            </div>
                        </div>
                    </div>

                    {/* Statistiques Conducteur */}
                    <div className="dashboard-container">
                        <div className="driver-stats-card">
                            <h2>Statistiques Globales</h2>
                            <div className="info-item">
                                <span>Total de Missions:</span>
                                <span>{totalMissions}</span>
                            </div>
                            <div className="info-item">
                                <span>Total de Kilomètres Parcourus:</span>
                                <span>{totalKilometers} km</span>
                            </div>
                            <div className="info-item">
                                <span>Total de Véhicules Convoyés:</span>
                                <span>{totalVehicles}</span>
                            </div>
                            <div className="info-item">
                                <span>Temps Moyen de Livraison:</span>
                                <span>{averageDeliveryTime} heures</span>
                            </div>
                        </div>
                    </div>

                    {/* Recherche */}
                    <div className="driver-info-card">
                        <h2>Rechercher</h2>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Rechercher des missions..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button onClick={handleSearch}>Rechercher</button>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <div className="buttons-container">
                            <button className="section-button" onClick={() => scrollToSection(validatedRef)}>Validées</button>
                            <button className="section-button" onClick={() => scrollToSection(startedRef)}>Démarrées</button>
                            <button className="section-button" onClick={() => scrollToSection(terminatedRef)}>Terminées</button>
                            <button className="section-button" onClick={() => scrollToSection(finishedRef)}>Finalisées</button>
                        </div>
                    </div>

                    {/* Missions */}
                    <div className="mission-status-blocks">
                        <div ref={validatedRef}>
                            <h2>Missions Validées</h2>
                            {filterMissionsByStatus('validated').map((mission) => (
                                <FormCard key={mission._id} form={mission} />
                            ))}
                        </div>
                        <div ref={startedRef}>
                            <h2>Missions Démarrées</h2>
                            {filterMissionsByStatus('started').map((mission) => (
                                <FormCard key={mission._id} form={mission} />
                            ))}
                        </div>
                        <div ref={terminatedRef}>
                            <h2>Missions Terminées</h2>
                            {filterMissionsByStatus('terminated').map((mission) => (
                                <FormCard key={mission._id} form={mission} />
                            ))}
                        </div>
                        <div ref={finishedRef}>
                            <h2>Missions Finalisées</h2>
                            {filterMissionsByStatus('finished').map((mission) => (
                                <FormCard key={mission._id} form={mission} />
                            ))}
                        </div>
                    </div>

                    {/* Résultats de recherche */}
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            <h2>Résultats de la recherche</h2>
                            {searchResults.map((result) => (
                                <FormCard key={result._id} form={result} />
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default DriverDashboard;
