import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import '../CustomNavbar.css';

function CustomNavbar() {
  const [click, setClick] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [infoMenuOpen, setInfoMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('token');
  const userRole = JSON.parse(localStorage.getItem('user') || '{}').role;

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const toggleAccountMenu = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  const toggleInfoMenu = () => {
    setInfoMenuOpen(!infoMenuOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            ATE69
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={click ? faTimes : faBars} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <div className="nav-links" onClick={toggleAccountMenu}>
                Mon compte <FontAwesomeIcon icon={faCaretDown} />
              </div>
              {accountMenuOpen && (
                <ul className="dropdown-menu">
                  {!isAuthenticated ? (
                    <>
                      <li className="dropdown-item">
                        <Link
                          to="/register"
                          className="nav-links"
                          onClick={closeMobileMenu}
                        >
                          Inscription
                        </Link>
                      </li>
                      <li className="dropdown-item">
                        <Link
                          to="/login"
                          className="nav-links"
                          onClick={closeMobileMenu}
                        >
                          Connexion
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="dropdown-item">
                        <Link
                          to={`/${userRole}/dashboard`}
                          className="nav-links"
                          onClick={closeMobileMenu}
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li className="dropdown-item">
                        <Link
                          to="/account"
                          className="nav-links"
                          onClick={closeMobileMenu}
                        >
                          Mon Compte
                        </Link>
                      </li>
                      <li className="dropdown-item">
                        <button
                          onClick={() => {
                            handleLogout();
                            closeMobileMenu();
                          }}
                          className="nav-links logout-button"
                        >
                          Déconnexion
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              )}
            </li>
            <li className="nav-item">
              <div className="nav-links" onClick={toggleInfoMenu}>
                Infos <FontAwesomeIcon icon={faCaretDown} />
              </div>
              {infoMenuOpen && (
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <Link
                      to="/occasionnel"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Occasionnel
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/regulier"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Régulier
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/lad"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      LAD
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/faq"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/cgv"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      CGV
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link
                      to="/privacy"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Politique de Confidentialité
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </nav>
      <div className="main-content">
        {/* Your main page content goes here */}
      </div>
    </>
  );
}

export default CustomNavbar;
