import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Met à jour l'état pour déclencher un rendu de secours
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log de l'erreur
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    
    // Si tu utilises un service de suivi des erreurs comme Sentry :
    if (window.Sentry) {
      window.Sentry.captureException(error, { extra: errorInfo });
    }

    // Met à jour l'état avec l'info de l'erreur
    this.setState({ error, errorInfo });
  }

  handleReload = () => {
    // Reload de la page ou navigation vers une autre route pour éviter de rester bloqué
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Personnalise l'affichage des erreurs
      return (
        <div className="error-boundary">
          <h2>Oops! Quelque chose s'est mal passé.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo ? this.state.errorInfo.componentStack : null}
          </details>
          <button onClick={this.handleReload}>Recharger l'application</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
