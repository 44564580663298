import React, { useState } from 'react';

function ReservesPickup({ onAddReserve }) {
  const [reserve, setReserve] = useState({
    part: '',
    subPart: '',
    damageType: '',
    size: '',
    photo: null,
    description: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setReserve((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setReserve((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleAddReserve = () => {
    let description = generateDescription(reserve);
    onAddReserve({ ...reserve, description });
    setReserve({
      part: '',
      subPart: '',
      damageType: '',
      size: '',
      photo: null,
      description: '',
    });
  };

  const generateDescription = ({ part, subPart, damageType, size }) => {
    if (damageType === 'Rayé' || damageType === 'Enfoncé') {
      return `Le ${subPart.toLowerCase()} ${part.toLowerCase()} est ${damageType.toLowerCase()} (taille: ${size})`;
    } else {
      return `Le ${subPart.toLowerCase()} ${part.toLowerCase()} est ${damageType.toLowerCase()}`;
    }
  };

  const parts = {
    'Face Avant': [
      'Capot',
      'Pare-choc avant',
      'Phare gauche',
      'Phare droit',
      'Calandre',
    ],
    'Latéral Avant Gauche': [
      'Aile avant gauche',
      'Porte avant gauche',
      'Rétroviseur avant gauche',
    ],
    'Latéral Avant Droit': [
      'Aile avant droite',
      'Porte avant droite',
      'Rétroviseur avant droit',
    ],
    'Latéral Arrière Gauche': [
      'Aile arrière gauche',
      'Porte arrière gauche',
      'Rétroviseur arrière gauche',
      'Fenêtre arrière gauche',
    ],
    'Latéral Arrière Droit': [
      'Aile arrière droite',
      'Porte arrière droite',
      'Rétroviseur arrière droit',
      'Fenêtre arrière droite',
    ],
    'Face Arrière': [
      'Pare-choc arrière',
      'Coffre',
      'Feu arrière gauche',
      'Feu arrière droit',
    ],
    Toit: ['Toit', 'Toit ouvrant', 'Antenne'],
    Intérieurs: [
      'Siège conducteur',
      'Siège passager avant',
      'Siège arrière gauche',
      'Siège arrière droit',
      'Tableau de bord',
    ],
    'Jantes/Pneumatique': [
      'Jante avant gauche',
      'Jante avant droite',
      'Jante arrière gauche',
      'Jante arrière droite',
      'Pneumatique avant gauche',
      'Pneumatique avant droite',
      'Pneumatique arrière gauche',
      'Pneumatique arrière droite',
    ],
    'Vitrages et optiques': [
      'Pare-brise avant',
      'Pare-brise arrière',
      'Vitre avant gauche',
      'Vitre avant droite',
      'Vitre arrière gauche',
      'Vitre arrière droite',
      'Rétroviseur intérieur',
      'Feu avant gauche',
      'Feu avant droit',
      'Feu arrière gauche',
      'Feu arrière droit',
    ],
  };

  const damageTypes = ['Rayé', 'Enfoncé', 'Cassé', 'Manquant'];
  const sizes = ['0-5cm', '5-10cm', '10-20cm', '+20cm'];

  return (
    <div>
      <h3>Ajouter une Réserve</h3>
      <div>
        <label>Partie Endommagée:</label>
        <select name="part" value={reserve.part} onChange={handleChange}>
          <option value="">Sélectionner</option>
          {Object.keys(parts).map((part) => (
            <option key={part} value={part}>
              {part}
            </option>
          ))}
        </select>
      </div>
      {reserve.part && (
        <div>
          <label>Sous-partie:</label>
          <select
            name="subPart"
            value={reserve.subPart}
            onChange={handleChange}
          >
            <option value="">Sélectionner</option>
            {parts[reserve.part].map((subPart) => (
              <option key={subPart} value={subPart}>
                {subPart}
              </option>
            ))}
          </select>
        </div>
      )}
      <div>
        <label>Type de Dommage:</label>
        <select
          name="damageType"
          value={reserve.damageType}
          onChange={handleChange}
        >
          <option value="">Sélectionner</option>
          {damageTypes.map((damage) => (
            <option key={damage} value={damage}>
              {damage}
            </option>
          ))}
        </select>
      </div>
      {(reserve.damageType === 'Rayé' || reserve.damageType === 'Enfoncé') && (
        <div>
          <label>Taille:</label>
          <select name="size" value={reserve.size} onChange={handleChange}>
            <option value="">Sélectionner</option>
            {sizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      )}
      <div>
        <label>Photo:</label>
        <input
          type="file"
          accept="image/*"
          capture="environment"
          name="photo"
          onChange={handleChange}
        />
      </div>
      <button type="button" onClick={handleAddReserve}>
        Ajouter Réserve
      </button>
    </div>
  );
}

export default ReservesPickup;
