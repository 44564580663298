import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

const PublicNetworkPage = () => {
  return (
    <div className="public-network-page">
      <Helmet>
        <title>ATE69 - Un Réseau de Convoyage Structuré et Performant</title>
        <meta name="description" content="Découvrez le réseau d'agences ATE-GROUP, structuré pour optimiser les services de convoyage avec des agences régionales et des franchises départementales." />
      </Helmet>

      {/* En-tête de la page */}
      <header className="bg-primary text-white text-center py-5">
        <div className="container">
          <h1 className="display-4">Le Réseau de Convoyage ATE69</h1>
          <p className="lead">Un maillage structuré pour des services de convoyage rapides et fiables.</p>
        </div>
      </header>

      {/* Présentation des agences régionales et franchises */}
      <section className="container py-5">
        <h2 className="text-primary mb-4">Les Agences Régionales et Franchises Départementales</h2>
        <p>
          **ATE-GROUP** supervise et finance les agences régionales qui forment le réseau principal de convoyage en France. Ces agences, telles qu’**ATE69** et **ATE33**, sont responsables des **transferts inter-agences régionales** et assurent la couverture locale dans leur département.
        </p>
        <p>
          Les **franchises départementales** comme ATE17 ou ATE38, sous la supervision de leurs agences régionales respectives, gèrent les enlèvements et livraisons finales dans leur département. Elles peuvent également effectuer des trajets longue distance et assurer des convoyages nationaux ou internationaux selon les besoins des clients.
        </p>
      </section>

      {/* Détails des agences régionales et leurs statuts */}
      <section className="bg-light py-5">
        <div className="container">
          <h3 className="text-primary mb-4">État Actuel et Expansions des Agences Régionales</h3>
          <div className="alert alert-info" role="alert">
            <strong>ATE69</strong> est déjà en opération, et **ATE33** est sur le point d'ouvrir.
          </div>
          <h5>Plan de Déploiement des Agences Régionales</h5>
          <ul className="list-group">
            <li className="list-group-item">ATE69 (Opérationnelle)</li>
            <li className="list-group-item">ATE33 (Ouverture Imminente)</li>
            <li className="list-group-item">ATE75 - Île-de-France (Prévue)</li>
            <li className="list-group-item">ATE35 - Bretagne</li>
            <li className="list-group-item">ATE64 - Nouvelle-Aquitaine</li>
            <li className="list-group-item">ATE30 - Occitanie</li>
            <li className="list-group-item">ATE59 - Hauts-de-France</li>
            <li className="list-group-item">ATE67 - Grand Est</li>
          </ul>
          <p className="mt-4">
            **ATE-GROUP** se concentre actuellement sur le développement d’un maillage national solide avec ces 8 agences, avant d’étendre son réseau vers l’Europe. Les futures implantations européennes incluront la Pologne, l'Allemagne, l'Espagne et l'Italie, renforçant ainsi le réseau pour atteindre un service de convoyage paneuropéen.
          </p>
        </div>
      </section>

      {/* Fonctionnement international */}
      <section className="container py-5">
        <h2 className="text-primary mb-4">Gestion des Convoyages Internationaux</h2>
        <p>
          Pour les départs ou arrivées internationales, chaque agence régionale en France gère les enlèvements et dépôts sur les points de transit internationaux (ports, aéroports, parcs de transit rail/camion). Par exemple, pour des envois en Chine, **ATE59** se charge des convoyages vers les ports tels que Le Havre.
        </p>
        <p>
          **ATE30** (Occitanie) gère également les convoyages à destination de la Corse, tandis que **ATE59** s’occupera des véhicules pour les destinations internationales partant des hubs de transit européens les plus proches.
        </p>
      </section>

      {/* Exemples de Fonctionnement du Réseau */}
      <section className="container py-5">
        <h3 className="text-primary mb-4">Exemple de Convoyage avec Relais Inter-Agences</h3>
        <p><strong>Enlèvement :</strong> 28/10/2024 à 8h00 à La Rochelle par ATE17</p>
        <p><strong>Livraison :</strong> 29/10/2024 à 10h00 à Grenoble via l'agence régionale ATE69</p>
        <ul className="list-unstyled">
          <li className="mb-3">
            <strong>Étape 1 :</strong> **ATE17**, franchisé local, effectue l’enlèvement à La Rochelle et transporte le véhicule jusqu’à **ATE33** à Bordeaux.
          </li>
          <li className="mb-3">
            <strong>Étape 2 :</strong> **ATE33** prend le relais et livre le véhicule à **ATE69** à Lyon.
          </li>
          <li className="mb-3">
            <strong>Étape 3 :</strong> **ATE38**, franchisé en Isère, effectue la livraison finale à Grenoble.
          </li>
        </ul>
      </section>

      {/* Exemple de convoyage international direct */}
      <section className="bg-light py-5">
        <div className="container">
          <h3 className="text-primary mb-4">Exemple de Convoyage Direct par Franchise</h3>
          <p><strong>Enlèvement :</strong> 30/10/2024 à 8h00 à Grenoble</p>
          <p><strong>Livraison :</strong> Directement à Milan, Italie</p>
          <ul className="list-unstyled">
            <li className="mb-3">
              <strong>Étape 1 :</strong> ATE38 prend en charge l'enlèvement à Grenoble et livre le véhicule directement à Milan.
            </li>
          </ul>
        </div>
      </section>

      {/* Conclusion */}
      <section className="container py-5">
        <h2 className="text-primary mb-4">Un Réseau de Convoyage Intégré et Performant</h2>
        <p>
          Grâce à ce réseau structuré, ATE-GROUP assure une couverture nationale et bientôt européenne, offrant une logistique de convoyage fiable pour les véhicules. Le maillage équilibré entre agences régionales et franchises départementales optimise chaque convoyage.
        </p>
      </section>

      {/* Appel à l'action */}
      <section className="bg-primary text-white text-center py-5">
        <div className="container">
          <h2>Découvrez Nos Services de Convoyage</h2>
          <p>ATE69 et ses partenaires sont là pour répondre à vos besoins en convoyage, qu’ils soient régionaux, nationaux ou internationaux.</p>
          <a href="https://ate69.fr/contact" className="btn btn-light mt-3">En savoir plus</a>
        </div>
      </section>
    </div>
  );
};

export default PublicNetworkPage;
