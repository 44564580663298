import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormCard from './FormCard';

const SentForms = ({ customIdC, status }) => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!customIdC || !status) {
      setError('Custom ID du client ou statut non fourni.');
      setLoading(false);
      console.error('Custom ID du client ou statut non fourni.');
      return;
    }

    const fetchForms = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non fourni.');
        }

        const response = await axios.get(
          `https://ate69.fr:5003/api/convoyages/${status}/${customIdC}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setForms(response.data);
      } catch (error) {
        console.error(
          `Erreur lors de la récupération des formulaires ${status}:`,
          error
        );
        setError(`Erreur lors de la récupération des formulaires ${status}`);
      } finally {
        setLoading(false);
      }
    };

    fetchForms();
  }, [customIdC, status]);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur: {error}</div>;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {forms.length > 0 ? (
          forms.map((form) => <FormCard key={form._id} form={form} />)
        ) : (
          <p>Aucun formulaire {status} trouvé.</p>
        )}
      </div>
    </div>
  );
};

export default SentForms;
