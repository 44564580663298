import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Card,
  Button,
  Form,
  Row,
  Col,
  Container,
  Spinner,
  Alert,
} from 'react-bootstrap';
import '../../css/admin_dash.css';

function AdminFinancialDetails() {
  const { customIdC } = useParams(); // Récupère le customIdC depuis l'URL
  const [client, setClient] = useState(null);
  const [convoyages, setConvoyages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [financialData, setFinancialData] = useState({
    montant: '',
    type: '', // On ne définit pas ici directement le type d'acompte ou paiement final
    moyen: 'Virement',
    date: new Date().toISOString().substring(0, 10),
    customIdF: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchClientDetails();
  }, [customIdC]);

  const fetchClientDetails = async () => {
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      // Fetch client by customIdC
      const clientResponse = await axios.get(
        `https://ate69.fr:5001/api/clients/${customIdC}`,
        { headers }
      );
      const clientData = clientResponse.data;

      // Fetch convoyages for the specific client
      const convoyagesResponse = await axios.get(
        `https://ate69.fr:5003/api/convoyages`,
        { headers }
      );
      const clientConvoyages = convoyagesResponse.data.filter(
        (conv) => conv.clientDetails.customIdC === customIdC
      );

      setClient(clientData);
      setConvoyages(clientConvoyages);
    } catch (error) {
      console.error('Error fetching client and convoyages data:', error);
      setError('Erreur lors de la récupération des données client.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFinancialData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = async (e, type) => {
    e.preventDefault();

    if (financialData.montant <= 0) {
      alert('Le montant doit être supérieur à 0.');
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      // Déterminer le type de paiement et envoyer l'appel correspondant
      const paymentData = { ...financialData, type };
      await axios.post(
        `https://ate69.fr:5003/api/convoyages/${financialData.customIdF}/add-payment`,
        paymentData,
        { headers }
      );

      alert(
        `${type === 'Acompte' ? 'Acompte' : 'Paiement final'} enregistré avec succès.`
      );
      fetchClientDetails(); // Rafraîchir les données après enregistrement du paiement
    } catch (error) {
      console.error(`Erreur lors de l'enregistrement du ${type}:`, error);
      alert(`Erreur lors de l'enregistrement du ${type}.`);
    }
  };

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (error) return <Alert variant="danger">{error}</Alert>;

  if (!client) return <div>Client non trouvé.</div>;

  // Liste 1 : Convoyages sans acompte versé (acompte total dû)
  const convoyagesWithPendingAdvance = convoyages.filter(
    (conv) =>
      conv.pricingDetails.advancePending > 0 &&
      conv.pricingDetails.advancePaid === 0
  );

  // Liste 2 : Convoyages terminés ou finalisés avec paiement final à verser
  const convoyagesFinalToPay = convoyages.filter(
    (conv) =>
      (conv.status === 'terminated' || conv.status === 'finished') &&
      conv.pricingDetails.finalAmountDue > 0
  );

  return (
    <Container className="mt-5">
      <Card>
        <Card.Header>
          <h2>Détails financiers pour {client.companyName}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h4>Informations Client</h4>
              <p>
                <strong>Email:</strong> {client.email}
              </p>
              <p>
                <strong>Téléphone:</strong> {client.phone}
              </p>
            </Col>
            <Col>
              <h4>Informations Financières</h4>
              <p>
                <strong>Accompte à verser:</strong>{' '}
                {convoyagesWithPendingAdvance
                  .reduce(
                    (sum, conv) =>
                      sum + (conv.pricingDetails.advancePending || 0),
                    0
                  )
                  .toFixed(2)}{' '}
                €
              </p>
              <p>
                <strong>Accompte versé:</strong>{' '}
                {convoyages
                  .reduce(
                    (sum, conv) => sum + (conv.pricingDetails.advancePaid || 0),
                    0
                  )
                  .toFixed(2)}{' '}
                €
              </p>
              <p>
                <strong>Montant estimé dû:</strong>{' '}
                {convoyages
                  .reduce(
                    (sum, conv) => sum + (conv.pricingDetails.totalPrice || 0),
                    0
                  )
                  .toFixed(2)}{' '}
                €
              </p>
              <p>
                <strong>Montant final dû:</strong>{' '}
                {(
                  convoyages.reduce(
                    (sum, conv) => sum + (conv.pricingDetails.totalPrice || 0),
                    0
                  ) -
                  convoyages.reduce(
                    (sum, conv) => sum + (conv.pricingDetails.advancePaid || 0),
                    0
                  )
                ).toFixed(2)}{' '}
                €
              </p>
              <p>
                <strong>Total paiements reçus:</strong>{' '}
                {convoyages
                  .reduce(
                    (sum, conv) =>
                      sum +
                      conv.payments.reduce(
                        (paymentSum, payment) =>
                          paymentSum + (payment.montant || 0),
                        0
                      ),
                    0
                  )
                  .toFixed(2)}{' '}
                €
              </p>
            </Col>
          </Row>
          <hr />
          {/* Formulaire pour l'acompte */}
          <h4>Renseigner un acompte</h4>
          <Form onSubmit={(e) => handleFormSubmit(e, 'Acompte')}>
            <Form.Group controlId="formConvoyageSelect1">
              <Form.Label>Convoyage pour acompte à verser</Form.Label>
              <Form.Control
                as="select"
                name="customIdF"
                value={financialData.customIdF}
                onChange={handleInputChange}
                required
              >
                <option value="">Sélectionner un convoyage</option>
                {convoyagesWithPendingAdvance.map((conv) => (
                  <option key={conv.customIdF} value={conv.customIdF}>
                    {conv.vehicleDetails.registration} ({conv.customIdF}) -
                    Acompte à verser: {conv.pricingDetails.advancePending} €
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="formMontant1">
                  <Form.Label>Montant (€)</Form.Label>
                  <Form.Control
                    type="number"
                    name="montant"
                    value={financialData.montant}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formMoyen1">
                  <Form.Label>Moyen de paiement</Form.Label>
                  <Form.Control
                    as="select"
                    name="moyen"
                    value={financialData.moyen}
                    onChange={handleInputChange}
                  >
                    <option value="Virement">Virement</option>
                    <option value="Carte">Carte</option>
                    <option value="Espèces">Espèces</option>
                    <option value="Chèque">Chèque</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="mt-3">
              Enregistrer acompte
            </Button>
          </Form>
          <hr />
          {/* Formulaire pour le paiement final */}
          <h4>Renseigner un paiement final</h4>
          <Form onSubmit={(e) => handleFormSubmit(e, 'Montant_du')}>
            <Form.Group controlId="formConvoyageSelect2">
              <Form.Label>Convoyage pour paiement final à verser</Form.Label>
              <Form.Control
                as="select"
                name="customIdF"
                value={financialData.customIdF}
                onChange={handleInputChange}
                required
              >
                <option value="">Sélectionner un convoyage</option>
                {convoyagesFinalToPay.map((conv) => (
                  <option key={conv.customIdF} value={conv.customIdF}>
                    {conv.vehicleDetails.registration} ({conv.customIdF}) -
                    Montant final à verser: {conv.pricingDetails.finalAmountDue}{' '}
                    €
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="formMontant2">
                  <Form.Label>Montant (€)</Form.Label>
                  <Form.Control
                    type="number"
                    name="montant"
                    value={financialData.montant}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formMoyen2">
                  <Form.Label>Moyen de paiement</Form.Label>
                  <Form.Control
                    as="select"
                    name="moyen"
                    value={financialData.moyen}
                    onChange={handleInputChange}
                  >
                    <option value="Virement">Virement</option>
                    <option value="Carte">Carte</option>
                    <option value="Espèces">Espèces</option>
                    <option value="Chèque">Chèque</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="mt-3">
              Enregistrer paiement final
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AdminFinancialDetails;
