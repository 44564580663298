import React from 'react';
import { Link } from 'react-router-dom';

function AdminDashboard() {
  const user = JSON.parse(localStorage.getItem('user'));

  // Vérification si l'utilisateur est un admin administratif ou technique
  const isAdminAdministratif =
    user.role === 'admin' &&
    (user.fonction === 'administratif' || user.fonction === 'technique');

  return (
    <div>
      <h1>Tableau de Bord Admin</h1>
      <nav>
        <ul>
          <li>
            <Link to="/admin/users">Gestion des Utilisateurs</Link>
          </li>
          <li>
            <Link to="/admin/forms">Gestion des Formulaires</Link>
          </li>
          {isAdminAdministratif && (
            <li>
              <Link to="/admin/financial">Gestion Financière</Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default AdminDashboard;
