import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import DriverFormViewEnl from './DriverFormViewEnl'; // Assurez-vous que le chemin est correct

function DriverFormViewDel() {
  const { convoyageId } = useParams();
  const navigate = useNavigate();
  const [convoyageDetails, setConvoyageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchConvoyageDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token is not available.');
        }
        const response = await axios.get(
          `https://ate69.fr:5003/api/convoyages/${convoyageId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setConvoyageDetails(response.data);
      } catch (error) {
        console.error('Failed to fetch convoyage details:', error);
        setError('Failed to fetch convoyage details');
      } finally {
        setLoading(false);
      }
    };

    fetchConvoyageDetails();
  }, [convoyageId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!convoyageDetails) return <div>No details found.</div>;

  // Function to extract and return an array of all photo URLs
  const getPhotos = (check) => {
    return Object.keys(check)
      .filter((key) => key.includes('Photo') || key === 'reserves') // Assuming reserves is an array of photo URLs
      .reduce((acc, key) => acc.concat(check[key]), []);
  };

  return (
    <div>
      <h2>Details de Convoyage Complet</h2>
      <DriverFormViewEnl />
      <div>
        <h3>État des Lieux Livraison</h3>
        <p>
          Date de livraison:{' '}
          {new Date(
            convoyageDetails.deliveryDetails.deliveryDate
          ).toLocaleString()}
        </p>
        <p>Péage: {convoyageDetails.deliveryCheck.peage}€</p>
        <p>Carburant: {convoyageDetails.deliveryCheck.carburant}€</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gridGap: '1rem',
          }}
        >
          {getPhotos(convoyageDetails.deliveryCheck).map((photoUrl, index) => (
            <div key={index} style={{ textAlign: 'center' }}>
              <img
                src={photoUrl}
                alt={`Photo ${index}`}
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
          ))}
        </div>
      </div>
      <button onClick={() => navigate(-1)}>Retour</button>
    </div>
  );
}

export default DriverFormViewDel;
