import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Account.css';
import Stats from './Stats';

const jours = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const initializeOpeningHours = () => {
  return jours.map((jour) => ({
    jour,
    closed: false,
    hasBreak: false,
    times: [
      { from: '09:00', to: '18:00' },
      { from: '', to: '' },
    ],
  }));
};

const ensureValidOpeningHours = (openingHours) => {
  return jours.map((jour, index) => {
    const existingDay = openingHours.find((day) => day.jour === jour);
    if (existingDay) {
      return {
        ...existingDay,
        times:
          existingDay.times && existingDay.times.length === 2
            ? existingDay.times
            : [
                { from: '09:00', to: '18:00' },
                { from: '', to: '' },
              ],
      };
    } else {
      return {
        jour,
        closed: false,
        hasBreak: false,
        times: [
          { from: '09:00', to: '18:00' },
          { from: '', to: '' },
        ],
      };
    }
  });
};

const generateTimeOptions = (start, end) => {
  const options = [];
  for (let hour = start; hour <= end; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      options.push(
        <option key={time} value={time}>
          {time}
        </option>
      );
    }
  }
  return options;
};

function Account() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [openingHours, setOpeningHours] = useState(initializeOpeningHours());
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      if (isDataFetched) return;

      try {
        let userId;
        if (user.role === 'admin') {
          userId = user.customIdA;
        } else if (user.role === 'driver') {
          userId = user.customIdD;
        } else if (user.role === 'client') {
          userId = user.customIdC;
        }

        if (!userId) {
          throw new Error('Identifiant utilisateur introuvable.');
        }

        const baseUrl = `https://ate69.fr:500${user.role === 'admin' ? '0' : user.role === 'driver' ? '2' : '1'}`;
        const userInfoUrl = `${baseUrl}/api/${user.role}s/${userId}`;

        const response = await axios.get(userInfoUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserInfo(response.data);
        setPhone(response.data.phone);
        if (
          response.data.openingHours &&
          response.data.openingHours.length > 0
        ) {
          setOpeningHours(ensureValidOpeningHours(response.data.openingHours));
        }

        setIsDataFetched(true);
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des informations de l’utilisateur:',
          error
        );
      }
    };

    fetchData();
  }, [navigate, token, user, isDataFetched]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      let userId;
      if (user.role === 'admin') {
        userId = user.customIdA;
      } else if (user.role === 'driver') {
        userId = user.customIdD;
      } else if (user.role === 'client') {
        userId = user.customIdC;
      }

      if (!userId) {
        throw new Error('Identifiant utilisateur introuvable.');
      }

      const baseUrl = `https://ate69.fr:500${user.role === 'admin' ? '0' : user.role === 'driver' ? '2' : '1'}`;
      const updateUrl = `${baseUrl}/api/${user.role}s/update/${userId}`;

      await axios.put(
        updateUrl,
        { password, phone },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success('Mot de passe et téléphone mis à jour avec succès.');
    } catch (error) {
      console.error(
        'Erreur lors de la mise à jour du mot de passe ou du téléphone:',
        error
      );
      toast.error(
        'Une erreur est survenue lors de la mise à jour de votre mot de passe ou de votre téléphone.'
      );
    }
  };

  const handleUpdateOpeningHours = async () => {
    try {
      let userId;
      if (user.role === 'admin') {
        userId = user.customIdA;
      } else if (user.role === 'driver') {
        userId = user.customIdD;
      } else if (user.role === 'client') {
        userId = user.customIdC;
      }

      if (!userId) {
        throw new Error('Identifiant utilisateur introuvable.');
      }

      const baseUrl = `https://ate69.fr:500${user.role === 'admin' ? '0' : user.role === 'driver' ? '2' : '1'}`;
      const updateUrl = `${baseUrl}/api/${user.role}s/update/${userId}`;

      await axios.put(
        updateUrl,
        { openingHours },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success("Horaires d'ouverture mis à jour avec succès.");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des horaires d'ouverture:",
        error
      );
      toast.error(
        "Une erreur est survenue lors de la mise à jour de vos horaires d'ouverture."
      );
    }
  };

  const handleOpeningHoursChange = (dayIndex, timeIndex, field, value) => {
    const newOpeningHours = [...openingHours];
    if (!newOpeningHours[dayIndex].times[timeIndex]) {
      newOpeningHours[dayIndex].times[timeIndex] = { from: '', to: '' };
    }
    newOpeningHours[dayIndex].times[timeIndex][field] = value || '';
    setOpeningHours(newOpeningHours);
  };

  const handleClosedChange = (dayIndex) => {
    const newOpeningHours = [...openingHours];
    newOpeningHours[dayIndex].closed = !newOpeningHours[dayIndex].closed;
    setOpeningHours(newOpeningHours);
  };

  const handleBreakChange = (dayIndex) => {
    const newOpeningHours = [...openingHours];
    newOpeningHours[dayIndex].hasBreak = !newOpeningHours[dayIndex].hasBreak;
    if (!newOpeningHours[dayIndex].hasBreak) {
      newOpeningHours[dayIndex].times[1] = { from: '', to: '' };
    }
    setOpeningHours(newOpeningHours);
  };

  const handleNextDay = async () => {
    await handleUpdateOpeningHours();
    if (currentDayIndex < jours.length - 1) {
      setCurrentDayIndex(currentDayIndex + 1);
    }
  };

  const handlePrevDay = async () => {
    await handleUpdateOpeningHours();
    if (currentDayIndex > 0) {
      setCurrentDayIndex(currentDayIndex - 1);
    }
  };

  const handleDayChange = async (index) => {
    await handleUpdateOpeningHours();
    setCurrentDayIndex(index);
  };

  return (
    <div className="account-container">
      <h2>Mettre à jour mon compte ATE69</h2>
      <ToastContainer />

      {userInfo && (
        <div className="user-details-card">
          <h5 className="card-title">Informations du compte</h5>
          <p className="card-text">
            <strong>Nom :</strong> {userInfo.name}
          </p>
          <p className="card-text">
            <strong>Prénom :</strong> {userInfo.surname}
          </p>
          <p className="card-text">
            <strong>Adresse :</strong> {userInfo.address}
          </p>
          <p className="card-text">
            <strong>Téléphone :</strong>
            <input
              type="text"
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </p>
          {userInfo.siret && (
            <p className="card-text">
              <strong>SIRET :</strong> {userInfo.siret}
            </p>
          )}
          {userInfo.licenseNumber && (
            <p className="card-text">
              <strong>Numéro de permis :</strong> {userInfo.licenseNumber}
            </p>
          )}
          {userInfo.licenseYear && (
            <p className="card-text">
              <strong>Année d'obtention du permis :</strong>{' '}
              {userInfo.licenseYear}
            </p>
          )}
          {userInfo.rib && (
            <p className="card-text">
              <strong>RIB :</strong> {userInfo.rib}
            </p>
          )}
          {userInfo.companyName && (
            <p className="card-text">
              <strong>Nom de l'entreprise :</strong> {userInfo.companyName}
            </p>
          )}
          {userInfo.companyAddress && (
            <p className="card-text">
              <strong>Adresse de l'entreprise :</strong>{' '}
              {userInfo.companyAddress?.streetType}{' '}
              {userInfo.companyAddress?.number}{' '}
              {userInfo.companyAddress?.streetName},{' '}
              {userInfo.companyAddress?.postalCode}{' '}
              {userInfo.companyAddress?.city}
            </p>
          )}
          {userInfo.apeCode && (
            <p className="card-text">
              <strong>Code APE :</strong> {userInfo.apeCode}
            </p>
          )}
          {userInfo.companyType && (
            <p className="card-text">
              <strong>Type de société :</strong> {userInfo.companyType}
            </p>
          )}
        </div>
      )}

      {userInfo && userInfo.role === 'client' && (
        <div className="opening-hours">
          <h3>Horaires d'ouverture</h3>
          <div className="day-menu">
            {jours.map((jour, index) => (
              <button
                key={index}
                className={`btn btn-link ${currentDayIndex === index ? 'active' : ''}`}
                onClick={() => handleDayChange(index)}
              >
                {jour}
              </button>
            ))}
          </div>
          <div className="day-container">
            <h4>{openingHours[currentDayIndex]?.jour}</h4>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id={`closed-${openingHours[currentDayIndex]?.jour}`}
                checked={openingHours[currentDayIndex]?.closed || false}
                onChange={() => handleClosedChange(currentDayIndex)}
              />
              <label
                className="form-check-label"
                htmlFor={`closed-${openingHours[currentDayIndex]?.jour}`}
              >
                Êtes-vous fermé ?
              </label>
            </div>
            {!openingHours[currentDayIndex]?.closed && (
              <div>
                <div className="time-slot">
                  <label className="mr-2">De :</label>
                  <select
                    className="form-control"
                    value={openingHours[currentDayIndex]?.times[0]?.from || ''}
                    onChange={(e) =>
                      handleOpeningHoursChange(
                        currentDayIndex,
                        0,
                        'from',
                        e.target.value
                      )
                    }
                  >
                    {generateTimeOptions(7, 19)}
                  </select>
                  <label className="mr-2">À :</label>
                  <select
                    className="form-control"
                    value={openingHours[currentDayIndex]?.times[0]?.to || ''}
                    onChange={(e) =>
                      handleOpeningHoursChange(
                        currentDayIndex,
                        0,
                        'to',
                        e.target.value
                      )
                    }
                  >
                    {generateTimeOptions(7, 19)}
                  </select>
                </div>
                <div className="form-check form-check-inline mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`break-${openingHours[currentDayIndex]?.jour}`}
                    checked={openingHours[currentDayIndex]?.hasBreak || false}
                    onChange={() => handleBreakChange(currentDayIndex)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`break-${openingHours[currentDayIndex]?.jour}`}
                  >
                    Y a-t-il une coupure ?
                  </label>
                </div>
                {openingHours[currentDayIndex]?.hasBreak && (
                  <div className="time-slot">
                    <label className="mr-2">De :</label>
                    <select
                      className="form-control"
                      value={
                        openingHours[currentDayIndex]?.times[1]?.from || ''
                      }
                      onChange={(e) =>
                        handleOpeningHoursChange(
                          currentDayIndex,
                          1,
                          'from',
                          e.target.value
                        )
                      }
                    >
                      {generateTimeOptions(7, 19)}
                    </select>
                    <label className="mr-2">À :</label>
                    <select
                      className="form-control"
                      value={openingHours[currentDayIndex]?.times[1]?.to || ''}
                      onChange={(e) =>
                        handleOpeningHoursChange(
                          currentDayIndex,
                          1,
                          'to',
                          e.target.value
                        )
                      }
                    >
                      {generateTimeOptions(7, 19)}
                    </select>
                  </div>
                )}
              </div>
            )}
            <div className="navigation-buttons">
              <button
                className="btn btn-secondary"
                onClick={handlePrevDay}
                disabled={currentDayIndex === 0}
              >
                Précédent
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleNextDay}
                disabled={currentDayIndex === jours.length - 1}
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleUpdatePassword}>
        <div className="form-group">
          <label htmlFor="password">Nouveau mot de passe :</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirmer le mot de passe :</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Mettre à jour
        </button>
      </form>

      {userInfo && (
        <div className="account-warning">
          <p>
            Pour toute autre demande de modification, veuillez contacter le
            support technique (Asie) :
          </p>
          <p>
            <strong>Email:</strong> support@ate69.fr
          </p>
          <p>
            <strong>Note:</strong> Toute modification du SIRET ou de l'email
            entraînera la fermeture de votre compte actuel et la création d'un
            nouveau.
          </p>
        </div>
      )}

      {userInfo && <Stats userId={userInfo._id} role={user.role} />}
    </div>
  );
}

export default Account;
