import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Supprimez les données d'authentification stockées localement
    localStorage.clear();

    // Redirigez l'utilisateur vers la page de connexion
    navigate('/login');
  }, [navigate]);

  // Affichez un message ou un spinner pendant que la déconnexion est en cours
  return (
    <div>
      <p>Déconnexion en cours...</p>
    </div>
  );
}

export default LogoutPage;
