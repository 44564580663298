import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import Footer from './Footer';
import Header from './Header'; // Import immédiat du Header qui est critique

// Utilisation de lazy loading pour les composants non critiques
const Hero = lazy(() => import('./Hero'));
const CentreDeGestion = lazy(() => import('./CentreGestion'));
const Work = lazy(() => import('./Work'));
const Success = lazy(() => import('./Success'));
const Formules = lazy(() => import('./Formules'));
const QuelquesChiffres = lazy(() => import('./QuelquesChiffres'));
const Partners = lazy(() => import('./Partners'));

const HomePage = () => {
  const [currentTitle, setCurrentTitle] = useState(
    'Auto Transport Express - Spécialiste du Convoyage Automobile Léger'
  );
  const [currentDescription, setCurrentDescription] = useState(
    "Auto Transport Express offre des services de convoyage automobile léger de haute qualité en France et à l'international. Découvrez nos solutions sur mesure pour tous vos besoins de transport."
  );

  // Intersection observer refs
  const { ref: headerRef, inView: headerInView } = useInView({
    threshold: 0.5,
  });
  const { ref: heroRef, inView: heroInView } = useInView({ threshold: 0.5 });
  const { ref: gestionRef, inView: gestionInView } = useInView({
    threshold: 0.5,
  });
  const { ref: workRef, inView: workInView } = useInView({ threshold: 0.5 });
  const { ref: successRef, inView: successInView } = useInView({
    threshold: 0.5,
  });
  const { ref: formulesRef, inView: formulesInView } = useInView({
    threshold: 0.5,
  });
  const { ref: chiffresRef, inView: chiffresInView } = useInView({
    threshold: 0.5,
  });
  const { ref: partnersRef, inView: partnersInView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    // Fonction de nettoyage pour arrêter les effets si le composant est démonté
    let isMounted = true;

    if (headerInView && isMounted) {
      setCurrentTitle(
        'Auto Transport Express - Spécialiste du Convoyage Automobile Léger'
      );
      setCurrentDescription(
        "Auto Transport Express offre des services de convoyage automobile léger de haute qualité en France et à l'international. Découvrez nos solutions sur mesure pour tous vos besoins de transport."
      );
    } else if (heroInView && isMounted) {
      setCurrentTitle('Convoyage Automobile - Auto Transport Express');
      setCurrentDescription(
        "Découvrez nos services de convoyage automobile rapide et fiable, disponibles partout en France et à l'international. Profitez d'une solution de transport efficace et économique."
      );
    } else if (gestionInView && isMounted) {
      setCurrentTitle('Centre de Gestion des Convoyages - Lyon, France');
      setCurrentDescription(
        'Situé au cœur de Lyon, notre centre de gestion des convoyages se spécialise dans l’organisation sur-mesure de convoyage de véhicules légers.'
      );
    } else if (workInView && isMounted) {
      setCurrentTitle('ATE69 - Notre Travail');
      setCurrentDescription('Découvrez notre travail et nos réalisations.');
    } else if (successInView && isMounted) {
      setCurrentTitle('ATE69 - Nos Réussites');
      setCurrentDescription('Découvrez nos réussites et témoignages.');
    } else if (formulesInView && isMounted) {
      setCurrentTitle('ATE69 - Nos Formules de Convoyage');
      setCurrentDescription(
        'Découvrez nos différentes formules de convoyage automobile.'
      );
    } else if (chiffresInView && isMounted) {
      setCurrentTitle('ATE69 - Quelques Chiffres');
      setCurrentDescription(
        'Découvrez quelques chiffres clés sur nos services de convoyage automobile.'
      );
    } else if (partnersInView && isMounted) {
      setCurrentTitle('Nos Partenaires - ATE69');
      setCurrentDescription(
        'Découvrez nos partenaires de confiance avec qui nous travaillons étroitement.'
      );
    }

    return () => {
      // Nettoyage pour éviter les erreurs après le démontage
      isMounted = false;
    };
  }, [
    headerInView,
    heroInView,
    gestionInView,
    workInView,
    successInView,
    formulesInView,
    chiffresInView,
    partnersInView,
  ]);

  return (
    <div>
      <Helmet>
        <title>{currentTitle}</title>
        <meta name="description" content={currentDescription} />
      </Helmet>

      {/* Header chargé immédiatement */}
      <div ref={headerRef}>
        <Header
          setTitle={setCurrentTitle}
          setDescription={setCurrentDescription}
        />
      </div>

      {/* Lazy Loading pour les sections moins critiques */}
      <Suspense fallback={<div>Chargement...</div>}>
        <div ref={heroRef}>
          <Hero
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
        <div ref={gestionRef}>
          <CentreDeGestion
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
        <div ref={workRef}>
          <Work
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
        <div ref={successRef}>
          <Success
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
        <div ref={formulesRef}>
          <Formules
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
        <div ref={chiffresRef}>
          <QuelquesChiffres
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
        <div ref={partnersRef}>
          <Partners
            setTitle={setCurrentTitle}
            setDescription={setCurrentDescription}
          />
        </div>
      </Suspense>

      {/* Footer normal */}
      <Footer />
    </div>
  );
};

export default HomePage;
