import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header_C from '../../pages/client/Header_C';
import Hero_C from '../../pages/client/Hero_C';
import Footer from '../../pages/Footer';
import '../../css/register.css';

const ClientRegister = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    name: '',
    surname: '',
    gender: '',
    phone: '',
    mobile: '',
    siret: '',
    companyName: '',
    companyType: '',
    companyAddress: {
      number: '',
      streetType: 'Rue',
      streetName: '',
      postalCode: '',
      city: '',
    },
    capital: '',
    billingAddress: {
      siret: '',
      companyName: '',
      address: {
        number: '',
        streetType: 'Rue',
        streetName: '',
        postalCode: '',
        city: '',
      },
      billingEmail: '',
      billingPhone: '',
    },
  });
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [availableCapitals, setAvailableCapitals] = useState([]);
  const [isBillingSameAsCompany, setIsBillingSameAsCompany] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef(null);

  useEffect(() => {
    fetchAvailableCapitals();
  }, []);

  const fetchAvailableCapitals = async () => {
    try {
      const response = await axios.get('https://ate69.fr:5001/api/clients/capitals/available');
      setAvailableCapitals(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des capitales disponibles', error);
    }
  };

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[@$!%*?&]/.test(password)) strength += 1;
    setPasswordStrength(strength);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'password') {
      evaluatePasswordStrength(value);
    }

    if (name.startsWith('companyAddress.') || name.startsWith('billingAddress.address.')) {
      const [addressType, field] = name.split('.');
      setFormData((prev) => ({
        ...prev,
        [addressType]: {
          ...prev[addressType],
          [field]: value,
        },
      }));
    } else if (name.startsWith('billingAddress.')) {
      const [, field] = name.split('.');
      setFormData((prev) => ({
        ...prev,
        billingAddress: {
          ...prev.billingAddress,
          [field]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleBillingCopy = () => {
    setIsBillingSameAsCompany(!isBillingSameAsCompany);
    if (!isBillingSameAsCompany) {
      setFormData((prev) => ({
        ...prev,
        billingAddress: {
          siret: prev.siret,
          companyName: prev.companyName,
          address: { ...prev.companyAddress },
          billingEmail: prev.email,
          billingPhone: prev.phone,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        billingAddress: {
          siret: '',
          companyName: '',
          address: {
            number: '',
            streetType: 'Rue',
            streetName: '',
            postalCode: '',
            city: '',
          },
          billingEmail: '',
          billingPhone: '',
        },
      }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (formData.email !== formData.confirmEmail) {
      errors.email = 'Les emails ne correspondent pas';
    }

    if (formData.password !== formData.confirmPassword) {
      errors.password = 'Les mots de passe ne correspondent pas';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post('https://ate69.fr:5001/api/clients', formData);
      if (response.status === 201) {
        navigate('/ValidationWaitPage');
      } else {
        alert("Réponse inattendue lors de l'inscription. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : error.message || 'Erreur de connexion au serveur.';
      alert(`Erreur lors de l'inscription: ${errorMessage}`);
    }
  };

  const nextStep = () => {
    if (validateFormStep(step)) {
      setStep(step + 1);
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const prevStep = () => {
    setStep(step - 1);
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const validateFormStep = (step) => {
    const stepErrors = {};

    switch (step) {
      case 1:
        if (!formData.email || !formData.confirmEmail) {
          stepErrors.email = 'Veuillez renseigner votre email.';
        }
        if (!formData.password || !formData.confirmPassword) {
          stepErrors.password = 'Veuillez renseigner votre mot de passe.';
        }
        if (formData.email !== formData.confirmEmail) {
          stepErrors.confirmEmail = 'Les emails ne correspondent pas.';
        }
        if (formData.password !== formData.confirmPassword) {
          stepErrors.confirmPassword = 'Les mots de passe ne correspondent pas.';
        }
        break;
      case 2:
        if (!formData.name) {
          stepErrors.name = 'Veuillez renseigner votre nom.';
        }
        if (!formData.surname) {
          stepErrors.surname = 'Veuillez renseigner votre prénom.';
        }
        if (!formData.gender) {
          stepErrors.gender = 'Veuillez sélectionner votre genre.';
        }
        if (!formData.mobile) {
          stepErrors.mobile = 'Veuillez renseigner votre téléphone mobile.';
        }
        break;
      case 3:
        if (!formData.siret) {
          stepErrors.siret = 'Veuillez renseigner le SIRET.';
        }
        if (!formData.companyName) {
          stepErrors.companyName = "Veuillez renseigner le nom de l'entreprise.";
        }
        if (!formData.companyAddress.number || !formData.companyAddress.streetName || !formData.companyAddress.postalCode || !formData.companyAddress.city) {
          stepErrors.companyAddress = "Veuillez renseigner l'adresse complète de l'entreprise.";
        }
        if (!formData.phone) {
          stepErrors.phone = "Veuillez renseigner le téléphone fixe de l'entreprise.";
        }
        if (!formData.companyType) {
          stepErrors.companyType = 'Veuillez sélectionner le type de société.';
        }
        if (!formData.capital) {
          stepErrors.capital = 'Veuillez sélectionner une capitale.';
        }
        break;
      case 4:
        if (!formData.billingAddress.siret || !formData.billingAddress.companyName || !formData.billingAddress.address.number || !formData.billingAddress.address.streetName || !formData.billingAddress.address.postalCode || !formData.billingAddress.address.city || !formData.billingAddress.billingEmail) {
          stepErrors.billingAddress = "Veuillez renseigner toutes les informations de facturation.";
        }
        break;
      default:
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  return (
    <div>
      <Header_C className="header-full-width" />
      <div className="form-container" ref={formRef}>
        <form onSubmit={handleSubmit}>
          {/* Etape 1 */}
          {step === 1 && (
            <div className="step">
              <h2>Informations de connexion</h2>
              <div>
                <label htmlFor="email">Email :</label>
                <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} required />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div>
                <label htmlFor="confirmEmail">Confirmez l'Email :</label>
                <input type="email" name="confirmEmail" id="confirmEmail" value={formData.confirmEmail} onChange={handleChange} required />
                {errors.confirmEmail && <span className="error">{errors.confirmEmail}</span>}
              </div>
              <div>
                <label htmlFor="password">Mot de passe :</label>
                <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} required />
                {errors.password && <span className="error">{errors.password}</span>}
              </div>
              <div className="password-strength-bar">
                <div className={`strength-bar strength-${passwordStrength}`}></div>
                <p>Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.</p>
              </div>
              <div>
                <label htmlFor="confirmPassword">Confirmez le mot de passe :</label>
                <input type="password" name="confirmPassword" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
              </div>
              <button type="button" onClick={nextStep}>Suivant</button>
            </div>
          )}

          {/* Etape 2 */}
          {step === 2 && (
            <div className="step">
              <h2>Informations personnelles</h2>
              <div>
                <label htmlFor="gender">Genre :</label>
                <select name="gender" id="gender" value={formData.gender} onChange={handleChange} required>
                  <option value="">Sélectionnez votre genre</option>
                  <option value="male">Mr</option>
                  <option value="female">Mme</option>
                </select>
                {errors.gender && <span className="error">{errors.gender}</span>}
              </div>
              <div>
                <label htmlFor="name">Nom :</label>
                <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} required />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div>
                <label htmlFor="surname">Prénom :</label>
                <input type="text" name="surname" id="surname" value={formData.surname} onChange={handleChange} required />
                {errors.surname && <span className="error">{errors.surname}</span>}
              </div>
              <div>
                <label htmlFor="mobile">Téléphone mobile :</label>
                <input type="tel" name="mobile" id="mobile" placeholder="+33 (0) x xx xx xx xx" value={formData.mobile} onChange={handleChange} required />
                {errors.mobile && <span className="error">{errors.mobile}</span>}
              </div>
              <button type="button" onClick={prevStep}>Précédent</button>
              <button type="button" onClick={nextStep}>Suivant</button>
            </div>
          )}

          {/* Etape 3 */}
          {step === 3 && (
            <div className="step">
              <h2>Informations de l'entreprise</h2>
              <div>
                <label htmlFor="siret">SIRET :</label>
                <input type="text" name="siret" id="siret" value={formData.siret} onChange={handleChange} pattern="\d{14}" title="SIRET doit être composé de 14 chiffres" required />
                {errors.siret && <span className="error">{errors.siret}</span>}
              </div>
              <div>
                <label htmlFor="companyName">Nom de l'entreprise :</label>
                <input type="text" name="companyName" id="companyName" value={formData.companyName} onChange={handleChange} required />
                {errors.companyName && <span className="error">{errors.companyName}</span>}
              </div>
              <div className="address-line">
                <div>
                  <label htmlFor="companyAddress.number">Numéro :</label>
                  <input type="text" name="companyAddress.number" id="companyAddressNumber" value={formData.companyAddress.number} onChange={handleChange} required />
                  {errors['companyAddress.number'] && <span className="error">{errors['companyAddress.number']}</span>}
                </div>
                <div>
                  <label htmlFor="companyAddress.streetType">Type de voie :</label>
                  <select name="companyAddress.streetType" id="companyAddressStreetType" value={formData.companyAddress.streetType} onChange={handleChange} required>
                    <option value="Allée">Allée</option>
                    <option value="Avenue">Avenue</option>
                    <option value="Boulevard">Boulevard</option>
                    <option value="Chemin">Chemin</option>
                    <option value="Cours">Cours</option>
                    <option value="Impasse">Impasse</option>
                    <option value="Passage">Passage</option>
                    <option value="Place">Place</option>
                    <option value="Quai">Quai</option>
                    <option value="Route">Route</option>
                    <option value="Ruelle">Ruelle</option>
                    <option value="Rue">Rue</option>
                    <option value="Square">Square</option>
                    <option value="Voie">Voie</option>
                  </select>
                  {errors['companyAddress.streetType'] && <span className="error">{errors['companyAddress.streetType']}</span>}
                </div>
                <div>
                  <label htmlFor="companyAddress.streetName">Nom de la rue :</label>
                  <input type="text" name="companyAddress.streetName" id="companyAddressStreetName" value={formData.companyAddress.streetName} onChange={handleChange} required />
                  {errors['companyAddress.streetName'] && <span className="error">{errors['companyAddress.streetName']}</span>}
                </div>
              </div>
              <div className="address-line">
                <div>
                  <label htmlFor="companyAddress.postalCode">Code postal :</label>
                  <input type="text" name="companyAddress.postalCode" id="companyAddressPostalCode" value={formData.companyAddress.postalCode} onChange={handleChange} required />
                  {errors['companyAddress.postalCode'] && <span className="error">{errors['companyAddress.postalCode']}</span>}
                </div>
                <div>
                  <label htmlFor="companyAddress.city">Ville :</label>
                  <input type="text" name="companyAddress.city" id="companyAddressCity" value={formData.companyAddress.city} onChange={handleChange} required />
                  {errors['companyAddress.city'] && <span className="error">{errors['companyAddress.city']}</span>}
                </div>
              </div>
              <div>
                <label htmlFor="phone">Téléphone fixe de l'entreprise :</label>
                <input type="tel" name="phone" id="phone" placeholder="+33 (0) x xx xx xx xx" value={formData.phone} onChange={handleChange} required />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div>
                <label htmlFor="companyType">Type de société :</label>
                <select name="companyType" id="companyType" value={formData.companyType} onChange={handleChange} required>
                  <option value="">Sélectionnez un type de société</option>
                  <option value="Concession auto">Concession auto</option>
                  <option value="Agence de location auto">Agence de location auto</option>
                  <option value="Préparateur auto">Préparateur auto</option>
                  <option value="Mandataire/Importateur/exportateur auto">Mandataire/Importateur/exportateur auto</option>
                  <option value="Leasing et assurances">Leasing et assurances</option>
                  <option value="Transporteurs">Transporteurs</option>
                  <option value="Autres">Autres</option>
                </select>
                {errors.companyType && <span className="error">{errors.companyType}</span>}
              </div>
              <div>
                <label htmlFor="capital">Capitale :</label>
                <p>Chez ATE nous utilisons un système d'identifiant unique via les capitales mondiales. Chaque client peut librement choisir une Capitale disponible, qui servira lors de tout échange avec ATE.</p>
                <select name="capital" id="capital" value={formData.capital} onChange={handleChange} required>
                  <option value="">Sélectionnez une capitale</option>
                  {availableCapitals.map((capital, index) => (
                    <option key={index} value={capital}>{capital}</option>
                  ))}
                </select>
                {errors.capital && <span className="error">{errors.capital}</span>}
              </div>
              <button type="button" onClick={prevStep}>Précédent</button>
              <button type="button" onClick={nextStep}>Suivant</button>
            </div>
          )}

          {/* Etape 4 */}
          {step === 4 && (
            <div className="step">
              <h2>Informations de facturation</h2>
              <div>
                <label>
                  <input type="checkbox" checked={isBillingSameAsCompany} onChange={handleBillingCopy} />
                  Identique à l'entreprise
                </label>
              </div>
              <div>
                <label htmlFor="billingAddress.siret">SIRET de facturation :</label>
                <input type="text" name="billingAddress.siret" id="billingAddressSiret" value={formData.billingAddress.siret} onChange={handleChange} pattern="\d{14}" title="SIRET doit être composé de 14 chiffres" required />
                {errors.billingAddress && errors.billingAddress.siret && <span className="error">{errors.billingAddress.siret}</span>}
              </div>
              <div>
                <label htmlFor="billingAddress.companyName">Nom de la société :</label>
                <input type="text" name="billingAddress.companyName" id="billingAddressCompanyName" value={formData.billingAddress.companyName} onChange={handleChange} required />
                {errors.billingAddress && errors.billingAddress.companyName && <span className="error">{errors.billingAddress.companyName}</span>}
              </div>
              <div className="address-line">
                <div>
                  <label htmlFor="billingAddress.address.number">Numéro :</label>
                  <input type="text" name="billingAddress.address.number" id="billingAddressNumber" value={formData.billingAddress.address.number} onChange={handleChange} required />
                  {errors.billingAddress && errors.billingAddress.address && errors.billingAddress.address.number && <span className="error">{errors.billingAddress.address.number}</span>}
                </div>
                <div>
                  <label htmlFor="billingAddress.address.streetType">Type de voie :</label>
                  <select name="billingAddress.address.streetType" id="billingAddressStreetType" value={formData.billingAddress.address.streetType} onChange={handleChange} required>
                    <option value="Allée">Allée</option>
                    <option value="Avenue">Avenue</option>
                    <option value="Boulevard">Boulevard</option>
                    <option value="Chemin">Chemin</option>
                    <option value="Cours">Cours</option>
                    <option value="Impasse">Impasse</option>
                    <option value="Passage">Passage</option>
                    <option value="Place">Place</option>
                    <option value="Quai">Quai</option>
                    <option value="Route">Route</option>
                    <option value="Ruelle">Ruelle</option>
                    <option value="Rue">Rue</option>
                    <option value="Square">Square</option>
                    <option value="Voie">Voie</option>
                  </select>
                  {errors.billingAddress && errors.billingAddress.address && errors.billingAddress.address.streetType && <span className="error">{errors.billingAddress.address.streetType}</span>}
                </div>
                <div>
                  <label htmlFor="billingAddress.address.streetName">Nom de la rue :</label>
                  <input type="text" name="billingAddress.address.streetName" id="billingAddressStreetName" value={formData.billingAddress.address.streetName} onChange={handleChange} required />
                  {errors.billingAddress && errors.billingAddress.address && errors.billingAddress.address.streetName && <span className="error">{errors.billingAddress.address.streetName}</span>}
                </div>
              </div>
              <div className="address-line">
                <div>
                  <label htmlFor="billingAddress.address.postalCode">Code postal :</label>
                  <input type="text" name="billingAddress.address.postalCode" id="billingAddressPostalCode" value={formData.billingAddress.address.postalCode} onChange={handleChange} required />
                  {errors.billingAddress && errors.billingAddress.address && errors.billingAddress.address.postalCode && <span className="error">{errors.billingAddress.address.postalCode}</span>}
                </div>
                <div>
                  <label htmlFor="billingAddress.address.city">Ville :</label>
                  <input type="text" name="billingAddress.address.city" id="billingAddressCity" value={formData.billingAddress.address.city} onChange={handleChange} required />
                  {errors.billingAddress && errors.billingAddress.address && errors.billingAddress.address.city && <span className="error">{errors.billingAddress.address.city}</span>}
                </div>
              </div>
              <div>
                <label htmlFor="billingAddress.billingEmail">Email de facturation :</label>
                <input type="email" name="billingAddress.billingEmail" id="billingAddressEmail" value={formData.billingAddress.billingEmail} onChange={handleChange} required />
                {errors.billingAddress && errors.billingAddress.billingEmail && <span className="error">{errors.billingAddress.billingEmail}</span>}
              </div>
              <div>
                <label htmlFor="billingAddress.billingPhone">Téléphone de facturation :</label>
                <input type="tel" name="billingAddress.billingPhone" id="billingAddressPhone" placeholder="+33 (0) x xx xx xx xx" value={formData.billingAddress.billingPhone} onChange={handleChange} />
              </div>
              <button type="button" onClick={prevStep}>Précédent</button>
              <button type="submit">S'inscrire</button>
            </div>
          )}
        </form>
      </div>
	          <Hero_C className="hero-full-width" />

      <Footer className="footer-full-width" />
    </div>
  );
};

export default ClientRegister;
