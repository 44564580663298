import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import 'animate.css/animate.min.css';
import '../Footer.css';

const Footer = React.memo(() => {
  const [isVisible, setIsVisible] = useState(false);

  const debounce = (func, wait = 20) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleScroll = useCallback(() => {
    const element = document.getElementById('contact');
    if (element) {
      const rect = element.getBoundingClientRect();
      setIsVisible(rect.top < window.innerHeight && rect.bottom > 0);
    }
  }, []);

  useEffect(() => {
    const debouncedScroll = debounce(handleScroll, 100);
    window.addEventListener('scroll', debouncedScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', debouncedScroll);
    };
  }, [handleScroll]);

  return (
    <footer
      id="contact"
      className={`contact-footer ${isVisible ? 'in-view' : ''}`}
    >
      <div className="footer-content">
        <div
          className="footer-section contact"
          itemScope
          itemType="http://schema.org/Organization"
        >
          <h3 itemProp="name">Contactez-nous</h3>
          <p>
            Notre centre d’exploitation n’est malheureusement pas ouvert au
            public.
          </p>
          <p itemProp="telephone">+33 6 52 29 29 48</p>
          <p>
            <a href="mailto:ate69005@gmail.com" itemProp="email">
              ate69005@gmail.com
            </a>
          </p>
          <p
            itemProp="address"
            itemScope
            itemType="http://schema.org/PostalAddress"
          >
            <span itemProp="streetAddress">21 Quai Fulchiron</span>,
            <span itemProp="postalCode">69005</span>
            <span itemProp="addressLocality">LYON</span>
          </p>
        </div>

        <div className="footer-section links">
          <h3>Liens Utiles</h3>
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <Link to="/register">Inscription</Link>
            </li>
            <li>
              <Link to="/login">Connexion</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/cgv">CGV</Link>
            </li>
            <li>
              <Link to="/privacy">Politique de confidentialité</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section social">
          <h3>Suivez-nous</h3>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=100088499901338"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="icon"
                title="Suivez-nous sur Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/ate69005/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="icon"
                title="Suivez-nous sur Instagram"
              />
            </a>
            <a
              href="https://g.page/r/CfdzS8xtBAjDEBM/review"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faGoogle}
                className="icon"
                title="Suivez-nous sur Google"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          &copy; 2024 Auto Transport Express 69, ATE GROUP. Tous droits
          réservés.
        </p>
        <div>
          <Link to="/privacy">Politique de confidentialité</Link>
          <Link to="/terms-of-service">Conditions d'utilisation</Link>
        </div>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';

export default Footer;
