import React, { useState } from 'react';
import axios from './axiosConfig'; // Importez la configuration Axios

function EmailAccountForm({ account, setAccounts, isEditing, setIsEditing }) {
  const [formData, setFormData] = useState({
    userId: account ? account.userId : '',
    userType: account ? account.userType : 'admin',
    emailAddress: account ? account.emailAddress : '',
    password: '',
    emailActive: account ? account.emailActive : true,
    emailQuota: account ? account.emailQuota : 1024,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = isEditing ? `/accounts/${account._id}` : '/accounts';
    const method = isEditing ? 'put' : 'post';
    try {
      const response = await axios[method](endpoint, formData);
      setAccounts((prev) =>
        isEditing
          ? prev.map((ac) => (ac._id === account._id ? response.data : ac))
          : [...prev, response.data]
      );
      setIsEditing(false);
    } catch (error) {
      console.error(
        'Error managing email account',
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Form Fields */}
      <button type="submit">{isEditing ? 'Update' : 'Create'} Account</button>
    </form>
  );
}

export default EmailAccountForm;
