import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormCard from '../../components/ClientForms/FormCard';
import axios from 'axios';

function DriverDashboard() {
  const navigate = useNavigate();
  const [driverInfo, setDriverInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState({
    validated: [],
    started: [],
    terminated: [],
    finalized: [],
  });

  const fetchDriverInfo = async () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const driverCustomIdD = user.customIdD;

    if (!token || !driverCustomIdD) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(
        `https://ate69.fr:5002/api/drivers/${driverCustomIdD}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setDriverInfo(response.data);

      const formsResponse = await axios.get(
        `https://ate69.fr:5003/api/convoyages/driver/${driverCustomIdD}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Filtrez les formulaires pour n'afficher que ceux assignés à ce chauffeur
      const assignedForms = formsResponse.data.filter(
        (form) => form.assignedDriver?.customIdD === driverCustomIdD
      );

      const validatedForms = assignedForms.filter(
        (form) => form.status === 'validated'
      );
      const startedForms = assignedForms.filter(
        (form) => form.status === 'started'
      );
      const terminatedForms = assignedForms.filter(
        (form) => form.status === 'terminated'
      );
      const finalizedForms = assignedForms.filter(
        (form) => form.status === 'finalized'
      );

      setForms({
        validated: validatedForms,
        started: startedForms,
        terminated: terminatedForms,
        finalized: finalizedForms,
      });
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des informations ou des formulaires:',
        error
      );
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDriverInfo();
  }, [navigate]);

  if (loading) {
    return <div>Chargement des informations...</div>;
  }

  return (
    <div>
      <h1>Tableau de Bord du Conducteur ATE69</h1>
      {driverInfo ? (
        <>
          <h2>Infos Conducteur</h2>
          <p>Bienvenue, {driverInfo.name}!</p>
          <p>Email: {driverInfo.email}</p>

          <h2>Formulaires Validés</h2>
          {forms.validated.length > 0 ? (
            forms.validated.map((form) => (
              <FormCard
                key={form.customIdF}
                form={form}
                onFormUpdate={fetchDriverInfo}
              />
            ))
          ) : (
            <p>Aucun formulaire validé trouvé.</p>
          )}

          <h2>Formulaires Démarrés</h2>
          {forms.started.length > 0 ? (
            forms.started.map((form) => (
              <FormCard
                key={form.customIdF}
                form={form}
                onFormUpdate={fetchDriverInfo}
              />
            ))
          ) : (
            <p>Aucun formulaire démarré trouvé.</p>
          )}

          <h2>Formulaires Terminés</h2>
          {forms.terminated.length > 0 ? (
            forms.terminated.map((form) => (
              <FormCard
                key={form.customIdF}
                form={form}
                onFormUpdate={fetchDriverInfo}
              />
            ))
          ) : (
            <p>Aucun formulaire terminé trouvé.</p>
          )}

          <h2>Formulaires Finalisés</h2>
          {forms.finalized.length > 0 ? (
            forms.finalized.map((form) => (
              <FormCard
                key={form.customIdF}
                form={form}
                onFormUpdate={fetchDriverInfo}
              />
            ))
          ) : (
            <p>Aucun formulaire finalisé trouvé.</p>
          )}
        </>
      ) : (
        <p>Impossible de charger les informations du conducteur.</p>
      )}
    </div>
  );
}

export default DriverDashboard;
