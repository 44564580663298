import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/Notice.css';

const NoticeSection = () => {
  return (
    <section className="notice-section py-5">
      <Helmet>
        <title>Notice de Création de Compte | ATE69</title>
        <meta
          name="description"
          content="Informations nécessaires pour la création de compte professionnel sur ATE69."
        />
      </Helmet>
      <div className="container">
        <div className="notice-content bg-light p-5 rounded shadow-sm mx-auto" style={{ maxWidth: '80%' }}>
          <h1 className="text-primary mb-4">Création de Compte Professionnel ATE69</h1>
          <p className="lead">
            La création d'un compte professionnel est essentielle pour accéder aux services ATE69, notamment pour réserver et suivre des convoyages de véhicules.
          </p>

          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="info-block mb-4">
                <h3 className="text-secondary">Comptes Multiples pour une Entreprise</h3>
                <p>
                  Une entreprise peut créer plusieurs comptes. Par exemple, chaque vendeur d'une concession peut disposer d'un compte individuel sous le nom de l'entreprise.
                </p>
              </div>

              <div className="info-block mb-4">
                <h3 className="text-secondary">Adresses Email Uniques</h3>
                <p>
                  Chaque compte doit être associé à une adresse email unique, assurant un accès sécurisé et personnalisé. Aucune adresse email ne peut être réutilisée pour plusieurs comptes.
                </p>
              </div>

              <div className="info-block mb-4">
                <h3 className="text-secondary">Encours de Compte</h3>
                <p>
                  Chaque compte dispose d'un encours financier propre et est financièrement responsable de ses commandes. Les retards de paiement peuvent entraîner un blocage temporaire de tous les comptes associés à l'entreprise.
                </p>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="info-block mb-4">
                <h3 className="text-secondary">Informations de Facturation</h3>
                <p>
                  Veillez à renseigner des informations de facturation exactes, surtout si votre entreprise utilise un système de facturation décentralisé. Des erreurs dans ces informations pourraient restreindre votre accès aux services.
                </p>
              </div>

              <div className="info-block mb-4">
                <h3 className="text-secondary">Blocages Régionaux et Nationaux</h3>
                <p>
                  Les agences régionales peuvent également bloquer l'accès à leurs services pour des entreprises spécifiques, bloquant ainsi les franchises associées. ATE-GROUP peut bloquer l'accès à un client, à une entreprise, ou à un centre de facturation, limitant leurs services pour une durée maximale de cinq ans suivant le règlement d’un litige.
                </p>
              </div>

              <div className="info-block mb-4">
                <h3 className="text-secondary">Protection des Données</h3>
                <p>
                  ATE-GROUP respecte la confidentialité de vos informations. Vos données ne seront partagées qu'en interne, et les adresses email saisies dans les formulaires ne seront jamais utilisées pour de la prospection commerciale externe.
                </p>
                <p>
                  Tout professionnel, même non client ATE, ayant un véhicule pris en charge par un client ATE, peut créer un compte ATE pour suivre ses services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoticeSection;
