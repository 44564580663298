import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from './Footer'; // Import du Footer
import '../ValidationWaitPage.css'; // Import des styles

function ValidationWaitPage() {
  return (
    <div className="validation-wait-page d-flex flex-column min-vh-100">
      {/* Metadonnées pour le SEO */}
      <Helmet>
        <title>Validation en attente</title>
        <meta
          name="description"
          content="Votre compte est en cours de validation. Veuillez patienter."
        />
      </Helmet>

      {/* Contenu principal */}
      <div className="content container text-center flex-grow-1">
        <h1>Validation en attente</h1>
        <p>
          Votre compte est actuellement en cours de validation par nos équipes.
          Nous vérifions les demandes du lundi au vendredi, de 9h à 18h.
        </p>
        <p>
          Le processus de validation peut prendre jusqu'à 48 heures. Nous vous
          remercions pour votre patience.
        </p>
        <p>
          En cas d'urgence, vous pouvez nous contacter par mail à l'adresse
          suivante : <a href="mailto:ate69005@gmail.com">ate69005@gmail.com</a>{' '}
          ou par téléphone au <a href="tel:0652292948">+33 6 52 29 29 48</a>.
        </p>
        <p>
          Veuillez noter que nous pouvons vous demander des informations
          complémentaires pour finaliser la validation de votre compte. Nous
          nous réservons également le droit de valider ou de refuser votre
          demande selon les critères établis.
        </p>

        {/* Animation de chargement */}
        <div className="spinner my-4"></div>

        {/* Boutons de navigation */}
        <div className="buttons d-flex justify-content-center gap-3">
          <Link to="/" className="btn btn-primary">
            Accueil
          </Link>
          <Link to="/faq" className="btn btn-secondary">
            F.A.Q
          </Link>
          <Link to="/cgv" className="btn btn-secondary">
            C.G.V
          </Link>
        </div>
      </div>

      {/* Footer en bas de page */}
      <Footer />
    </div>
  );
}

export default ValidationWaitPage;
