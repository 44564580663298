import React from 'react';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import '../QuelquesChiffres.css';

function Counter({ end, suffix, inView }) {
  return (
    <div className="counter">
      {inView ? <CountUp end={end} duration={2} /> : 0}
      <span className="unit">{suffix}</span>
    </div>
  );
}

const QuelquesChiffres = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <section id="chiffres" className="chiffres-section" ref={ref}>
      <Helmet>
        <title>ATE69 - Quelques Chiffres</title>
        <meta
          name="description"
          content="Découvrez quelques chiffres clés sur nos services de convoyage automobile."
        />
      </Helmet>
      <h2>Quelques Chiffres</h2>
      <div className="chiffres-container">
        <div className="chiffre-card">
          <Counter end={6000} suffix=" KM" inView={inView} />
          <p>Nombre de KM moyen par semaine</p>
        </div>
        {/* <div className="chiffre-card">
          <Counter end={15} suffix=" voitures" inView={inView} />
          <p>Nombre de voitures par semaine (moyenne)</p>
        </div> */}
        <div className="chiffre-card">
          <Counter end={350} suffix=" €" inView={inView} />
          <p>Coût moyen d'un convoyage</p>
        </div>
        <div className="chiffre-card">
          <Counter end={400} suffix=" KM" inView={inView} />
          <p>Distance moyenne d'un convoyage</p>
        </div>
        <div className="chiffre-card">
          <Counter end={7} suffix=" Agences" inView={inView} />
          <p>ATE prévu pour 2026 en France</p>
        </div>
      </div>
    </section>
  );
};

export default QuelquesChiffres;
