// Importation des modules essentiels pour le routage, les composants et la gestion des erreurs
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';  // Gère le scroll vers le haut après chaque navigation
import CustomNavbar from './components/CustomNavbar'; // Barre de navigation personnalisée
import ErrorBoundary from './components/ErrorBoundary'; // Composant pour capturer et gérer les erreurs

// Importation des pages et composants
import HomePage from './pages/HomePage';
import RegisterPage from './pages/Register';
import ClientRegister from './pages/client/Client_Register';
import DriverRegister from './pages/driver/Driver_Register';
import AdminRegister from './pages/admin/Admin_Register';
import ValidationWaitPage from './pages/ValidationWaitPage';
import LoginPage from './pages/LoginPage';
import ResetPassword from './pages/ResetPassword';
import LogoutPage from './pages/LogoutPage';
import NotFoundPage from './pages/NotFoundPage';
import FAQ from './pages/FAQ';
import CGV from './pages/CGV';
import Occasionnel from './pages/Occasionnel';
import Regulier from './pages/Regulier';
import Privacy from './pages/Privacy';
import Franchise from './pages/Franchise'; // Importation de la page Franchise
import Maillage from './pages/Maillage'; // Importation de la page maillage

// Importation des pages spécifiques à l'admin
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminUser from './pages/admin/AdminUsers';
import AdminForms from './components/AdminForms/AdminForms';
import AdminFinancial from './pages/admin/AdminFinancial';
import AdminBookDriver from './components/AdminBookDriver';
import FinancialAdminDetails from './pages/admin/FinancialAdminDetails';
import FinalizeForm from './pages/admin/FinalizeForm';

// Importation des pages spécifiques au client
import ClientDashboard from './pages/client/ClientDashboard';
import NestorFormClient from './components/Nestor_Form_Client';

// Importation des pages spécifiques au driver
import DriverDashboard from './pages/driver/DriverDashboard';
import DriverAssignDetails from './components/DriverAssignDetail';
import DriverCheckForm from './components/DriverCheckForm';
import DriverCheckFormDel from './components/DriverCheckFormDel';
import StartedFormDetail from './components/StartedFormDetail';
import ClosedFormDetail from './components/ClosedFormDetail';
import DriverFormViewEnl from './components/DriverFormViewEnl';
import DriverFormViewDel from './components/DriverFormViewDel';

// Commenté car non utilisés actuellement, mais conservés au cas où
// import StartedDriverDetail from './components/StartedDriverDetail';
// import ClosedDriverDetail from './components/ClosedDriverDetail';

// Importation des composants pour la gestion des emails
import EmailAccountForm from './components/mail/EmailAccountForm';
import EmailAccountList from './components/mail/EmailAccountList';

// Importation de la gestion des comptes et des routes protégées
import Account from './components/Account';
import ProtectedRoute from './components/ProtectedRoute'; // Composant pour protéger les routes selon les rôles

// Fonction principale de l'application
function App() {
  return (
    <Router>
      {/* ScrollToTop gère la remontée en haut de la page après chaque navigation */}
      <ScrollToTop />
      
      {/* CustomNavbar affiche la barre de navigation */}
      <CustomNavbar />
      
      {/* ErrorBoundary gère les erreurs inattendues et affiche une page d'erreur en cas de problème */}
      <ErrorBoundary>
        {/* Routes contient toutes les routes de l'application */}
        <Routes>

          {/* Routes publiques, accessibles à tous */}
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/client" element={<ClientRegister />} />
          <Route path="/register/driver" element={<DriverRegister />} />
          <Route path="/register/admin" element={<AdminRegister />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/validation-wait" element={<ValidationWaitPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/cgv" element={<CGV />} />
          <Route path="/occasionnel" element={<Occasionnel />} />
          <Route path="/regulier" element={<Regulier />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/franchise" element={<Franchise />} />  {/* Nouvelle route pour la page Franchise */}
          <Route path="/maillage" element={<Maillage />} />  {/* Nouvelle route pour la page Maillage */}

          {/* Routes protégées (nécessitent une authentification et des rôles spécifiques) */}
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute role="admin">
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/users"
            element={
              <ProtectedRoute role="admin">
                <AdminUser />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/forms"
            element={
              <ProtectedRoute role="admin">
                <AdminForms />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/financial"
            element={
              <ProtectedRoute role="admin" fonction={['administratif', 'technique']}>
                <AdminFinancial />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/financial-details/:customIdC"
            element={
              <ProtectedRoute role="admin" fonction={['administratif', 'technique']}>
                <FinancialAdminDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/client/dashboard"
            element={
              <ProtectedRoute role="client">
                <ClientDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/driver/dashboard"
            element={
              <ProtectedRoute role="driver">
                <DriverDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/driverbook"
            element={
              <ProtectedRoute role="admin">
                <AdminBookDriver />
              </ProtectedRoute>
            }
          />

          <Route
            path="/driver-assign-details/:customIdF"
            element={
              <ProtectedRoute role="driver">
                <DriverAssignDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/driver-check-form/:id"
            element={
              <ProtectedRoute role="driver">
                <DriverCheckForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="/driver-check-form-del/:id"
            element={
              <ProtectedRoute role="driver">
                <DriverCheckFormDel />
              </ProtectedRoute>
            }
          />

          <Route
            path="/details/started/:id"
            element={
              <ProtectedRoute role="admin">
                <StartedFormDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/details/completed/:id"
            element={
              <ProtectedRoute role="admin">
                <ClosedFormDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/details/driver-enl/:customIdF"
            element={
              <ProtectedRoute allowedRoles={['driver', 'admin', 'client']}>
                <DriverFormViewEnl />
              </ProtectedRoute>
            }
          />

          <Route
            path="/details/driver-del/:convoyageId"
            element={
              <ProtectedRoute allowedRoles={['driver', 'admin', 'client']}>
                <DriverFormViewDel />
              </ProtectedRoute>
            }
          />

          <Route
            path="/nestor-form"
            element={
              <ProtectedRoute role="client">
                <NestorFormClient />
              </ProtectedRoute>
            }
          />

          {/* Gestion des comptes emails */}
          <Route
            path="/admin/email-accounts"
            element={
              <ProtectedRoute role="admin">
                <EmailAccountList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/email-account/new"
            element={
              <ProtectedRoute role="admin">
                <EmailAccountForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/email-account/edit/:id"
            element={
              <ProtectedRoute role="admin">
                <EmailAccountForm isEditing={true} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/finalize/:convoyageId"
            element={
              <ProtectedRoute role="admin">
                <FinalizeForm />
              </ProtectedRoute>
            }
          />

          {/* Route 404 pour toutes les routes non définies */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
