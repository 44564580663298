import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ children, role, fonction }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null au début
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const userRole = user.role || null;
  const userFonction = user.fonction || null;

  useEffect(() => {
    let isMounted = true;
    const checkToken = async () => {
      if (!token) {
        if (isMounted) setIsAuthenticated(false);
        return;
      }

      let url;
      switch (userRole) {
        case 'client':
          url = 'https://ate69.fr:5001/api/clients/verify-token';
          break;
        case 'driver':
          url = 'https://ate69.fr:5002/api/drivers/verify-token';
          break;
        case 'admin':
          url = 'https://ate69.fr:5000/api/admins/verify-token';
          break;
        default:
          if (isMounted) setIsAuthenticated(false);
          return;
      }

      try {
        const response = await axios.post(url, { token });
        if (isMounted) setIsAuthenticated(response.data.valid);
      } catch (error) {
        console.error('Erreur de vérification du token:', error);
        if (isMounted) setIsAuthenticated(false);
      }
    };

    checkToken();

    return () => {
      isMounted = false;
    };
  }, [token, userRole]);

  if (isAuthenticated === null) {
    return <div>Vérification en cours...</div>; // Utilisation d'un <div> au lieu d'un <p>
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (role && userRole !== role) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (fonction && !fonction.includes(userFonction)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
