import React, { useState } from 'react';
import Header from './Header'; // Assurez-vous que le chemin est correct
import Footer from './Footer'; // Assurez-vous que le chemin est correct
import '../css/FAQ.css'; // Assurez-vous d'importer votre fichier CSS

// Composant pour un élément de la FAQ
function FAQItem({ question, answer, query }) {
  const [isOpen, setIsOpen] = useState(false);

  // Mise en surbrillance des résultats de recherche
  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <mark key={index}>{part}</mark>
      ) : (
        part
      )
    );
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <h3>{highlightText(question, query)}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="faq-answer">
          <p>{highlightText(answer, query)}</p>
        </div>
      )}
    </div>
  );
}

// Composant pour une section de la FAQ
function FAQSection({ title, items, query }) {
  const filteredItems = items.filter(
    (item) =>
      item.question.toLowerCase().includes(query.toLowerCase()) ||
      item.answer.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="faq-section">
      <h2>{title}</h2>
      {filteredItems.length > 0 ? (
        filteredItems.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
            query={query}
          />
        ))
      ) : (
        <p>Aucune question ne correspond à votre recherche.</p>
      )}
    </div>
  );
}

// Composant principal pour la FAQ
function FAQ() {
  const [query, setQuery] = useState('');

  // Données de la FAQ
  const faqData = {
    general: [
      {
        question: "Qu'est-ce que ATE69 ?",
        answer:
          "ATE69 est une société spécialisée dans le convoyage automobile léger, offrant des services personnalisés pour le transport de véhicules en France et à l'international.",
      },
      {
        question: 'Où est situé le centre de gestion de ATE69 ?',
        answer:
          'Notre centre de gestion est situé à Lyon, au 21 Quai Fulchiron, 69005 LYON.',
      },
      {
        question: 'Quels sont les services proposés par ATE69 ?',
        answer:
          'Nous proposons des services de convoyage occasionnel, régulier, et de livraison à domicile.',
      },
      {
        question: 'Quels types de véhicules convoyer-vous ?',
        answer:
          'Nous convoyons des citadines, berlines, utilitaires, véhicules historiques, hypercars, et plus encore.',
      },
      {
        question:
          'Comment obtenir une estimation pour le convoyage de mon véhicule ?',
        answer:
          "Vous pouvez obtenir une estimation rapide en ligne en renseignant le type de véhicule, l'adresse d'enlèvement, et l'adresse de livraison.",
      },
      {
        question: 'Quelle est votre mission principale ?',
        answer:
          'Nous assurons une gestion efficace et sur-mesure des convoyages de véhicules légers, en orchestrant la logistique et en garantissant une qualité de service exceptionnelle.',
      },
      {
        question: 'Quels sont les avantages de choisir ATE69 ?',
        answer:
          'Nous offrons une expertise reconnue, une gestion de convoyage sans sous-traitance, et une équipe de convoyeurs expérimentés.',
      },
    ],
    convoyageOccasionnel: [
      {
        question: "Qu'est-ce que le service de convoyage occasionnel ?",
        answer:
          'Ce service est conçu pour des besoins de convoyage uniques ou annuels, offrant flexibilité et sécurité.',
      },
      {
        question: "À qui s'adresse le service de convoyage occasionnel ?",
        answer:
          'Il est destiné aux particuliers et aux professionnels ayant des besoins ponctuels de transport de véhicules.',
      },
      {
        question: 'Comment fonctionne le convoyage occasionnel ?',
        answer:
          'Nos convoyeurs prennent en charge votre véhicule avec soin, respectent les délais et assurent une livraison sécurisée.',
      },
      {
        question: 'Quels sont les avantages du convoyage occasionnel ?',
        answer:
          "Vous bénéficiez d'un service sur-mesure, sans engagement à long terme, avec une couverture d'assurance tout risque.",
      },
      {
        question:
          'Est-il possible de personnaliser le service de convoyage occasionnel ?',
        answer:
          'Oui, nous offrons plusieurs options comme le lavage intérieur/extérieur et la livraison avec le plein de carburant.',
      },
      {
        question:
          "Quelle est la couverture d'assurance pour le convoyage occasionnel ?",
        answer:
          "Une assurance tout risque couvre votre véhicule jusqu'à 80 000€ H.T.",
      },
      {
        question:
          'Quels sont les modes de paiement pour le convoyage occasionnel ?',
        answer:
          'Un acompte de 50% est requis à la commande, le solde étant dû à la livraison.',
      },
    ],
    convoyageRegulier: [
      {
        question: "Qu'est-ce que le service de convoyage régulier ?",
        answer:
          'Ce service est destiné aux entreprises ayant des besoins fréquents de convoyage pour leur flotte de véhicules.',
      },
      {
        question: "À qui s'adresse le service de convoyage régulier ?",
        answer:
          "Il s'adresse aux concessions, parcs auto, revendeurs, importateurs/exportateurs, loueurs automobiles, fabricants/préparateurs, transporteurs, assurances et leasings.",
      },
      {
        question: 'Quels sont les avantages du convoyage régulier ?',
        answer:
          "Les clients réguliers bénéficient d'une gestion simplifiée des livraisons, d'un programme de parrainage, et d'un accès à des services exclusifs comme la livraison à domicile.",
      },
      {
        question:
          'Comment fonctionne la gestion des livraisons pour le convoyage régulier ?',
        answer:
          'Nous gérons la planification, le contact avec le destinataire, la gestion des documents, et plus encore, pour assurer un service fluide et efficace.',
      },
      {
        question:
          "Quelles sont les conditions d'accès au service de convoyage régulier ?",
        answer:
          'Ce service est accessible aux clients ayant réalisé au moins 10 convoyages avec ATE69.',
      },
      {
        question: "Qu'est-ce que le service L.A.D (Livraison à Domicile) ?",
        answer:
          "Le service L.A.D offre une livraison directe chez l'acquéreur dans un rayon de 50 km autour de Lyon, avec des forfaits dégressifs selon la quantité.",
      },
      {
        question: 'Quels sont les avantages du programme de parrainage ?',
        answer:
          "Le programme de parrainage permet d'obtenir jusqu'à 10% de réduction sur les convoyages traditionnels.",
      },
    ],
    processusConvoyage: [
      {
        question: 'Comment se déroule de A à Z un convoyage ?',
        answer: `
                    1. Le client s'inscrit sur le site [ate69.fr/register](https://ate69.fr/register).
                    2. L'équipe administrative étudie la demande et la valide si les critères sont au vert.
                    3. Le client est averti par mail de la validation ainsi que l'équipe exploitation.
                    4. Une fois connecté sur [le dashboard](https://ate69.fr/login), le client accède à diverses informations.
                    5. Le formulaire de réservation de convoyage est intuitif, facile et prend moins d'une minute à remplir !
                    6. Le client doit faire parvenir le paiement de l'acompte (50%) par virement ou en espèces remise au convoyeur avant le départ.
                    7. L'équipe administrative met à jour les informations financières reçues.
                    8. Le client et l'équipe exploitation reçoivent une notification par mail.
                    9. L'exploitation planifie et assigne le convoyage à un driver.
                    10. Le client et l'expéditeur sont informés des dates, heures, et lieu d'enlèvement du véhicule.
                    11. Le driver arrive à l'enlèvement aux dates et heures prévues par ses propres moyens.
                    12. Le convoyage débute après un état des lieux d'enlèvement.
                    13. Le client et le destinataire sont avertis par mail de la prise en charge et de la date, lieu et heure estimée d'arrivée.
                    14. Le driver effectue un nouvel état des lieux à la livraison.
                    15. Le client est averti par mail avec le montant total restant à devoir.
                    16. La facture est émise, le paiement est dû à la livraison.
                    17. Dans le dashboard client, toutes les informations sur les convoyages, les états des lieux, etc., sont disponibles.
                `,
      },
      {
        question: "Comment se déroule l'enlèvement d'un véhicule ?",
        answer:
          "Un état des lieux complet est réalisé avec des photos, et un carnet CMR est rempli pour documenter l'état du véhicule avant le transport.",
      },
      {
        question: 'Que se passe-t-il lors de la livraison du véhicule ?',
        answer:
          'Un nouvel état des lieux est effectué, et le destinataire doit signer le carnet CMR pour confirmer la réception du véhicule.',
      },
      {
        question: 'Comment les réserves sont-elles gérées en cas de dommage ?',
        answer:
          "Les réserves doivent être notées lors de l'enlèvement et de la livraison, et toute réclamation doit être faite dans les 48 heures suivant la livraison.",
      },
      {
        question:
          'Quels documents sont nécessaires pour faire une réclamation ?',
        answer:
          'Le contrat de convoyage (CMR), des photos des dommages, et un devis pour les réparations doivent être fournis.',
      },
      {
        question:
          'Quelle est la procédure en cas de dommage constaté lors du transport ?',
        answer:
          "Notre service d'exploitation examinera les pièces fournies et comparera les photos de l'enlèvement et de la livraison pour déterminer la responsabilité.",
      },
      {
        question:
          'Quels sont les types de véhicules non éligibles pour le convoyage ?',
        answer:
          'Les véhicules de plus de 3,5 tonnes, les véhicules endommagés nécessitant une remorque, et les véhicules classés dangereux sont non éligibles.',
      },
      {
        question: 'Comment sont gérées les exceptions opérationnelles ?',
        answer:
          "En cas de conditions météorologiques extrêmes ou d'événements publics majeurs, nous discutons des options disponibles avec le client.",
      },
    ],
    conditionsVente: [
      {
        question: 'Quelles sont les modalités de paiement ?',
        answer:
          'Les nouveaux clients doivent verser un acompte de 50% à la commande, avec le solde à régler à la livraison.',
      },
      {
        question: 'Quels sont les frais associés au convoyage ?',
        answer:
          'Les frais incluent le transport, les péages, le carburant, et sont estimés en fonction du type de véhicule et des conditions de trajet.',
      },
      {
        question: 'Que se passe-t-il en cas de retard de paiement ?',
        answer:
          "Des intérêts de retard peuvent être appliqués, et la société peut suspendre les services en cours jusqu'au règlement des montants dus.",
      },
      {
        question:
          'Comment sont gérées les modifications des conditions de service ?',
        answer:
          'Toute modification substantielle des CGV sera communiquée au client, qui pourra résilier le contrat si les nouvelles conditions ne sont pas acceptables.',
      },
      {
        question: "Quelle est la politique d'annulation ?",
        answer:
          "Les annulations moins de 24 heures avant le service sont facturées à 100%, entre 25 et 48 heures à 50%, et aucune pénalité ne s'applique pour les annulations plus de 48 heures à l'avance.",
      },
      {
        question:
          'Quelle est la responsabilité de ATE69 en cas de contraventions ?',
        answer:
          "ATE69 est responsable des contraventions pour stationnement ou circulation pendant le convoyage, sauf si elles résultent d'un manquement du client à fournir un véhicule conforme.",
      },
    ],
    supportClient: [
      {
        question: 'Comment puis-je contacter ATE69 ?',
        answer:
          'Vous pouvez nous joindre au +33 7 83 91 31 22 ou par email à ate69005@gmail.com.',
      },
      {
        question:
          'Quels sont les horaires de disponibilité du service client ?',
        answer:
          'Nos horaires de disponibilité seront communiqués sur demande ou consultables sur notre site web.',
      },
      {
        question: "Comment puis-je suivre l'état de mon convoyage ?",
        answer:
          "Nous fournissons des rapports complets sur l'enlèvement et la livraison, incluant un état des lieux détaillé, disponibles via notre plateforme en ligne.",
      },
      {
        question: 'Quelle est la procédure pour poser une réclamation ?',
        answer:
          'Les réclamations doivent être formulées par écrit dans les cinq jours ouvrables suivant la réception du véhicule, avec toutes les preuves nécessaires.',
      },
      {
        question: "Comment puis-je m'inscrire au service de convoyage ?",
        answer:
          "L'inscription est réservée aux professionnels possédant un Kbis et exerçant dans des secteurs liés à l'automobile.",
      },
      {
        question: 'Quels sont les critères pour devenir un client régulier ?',
        answer:
          'Les clients réguliers sont ceux ayant réalisé au moins 10 convoyages avec ATE69 et qui ont des besoins fréquents de transport de véhicules.',
      },
      {
        question: 'Comment accéder à votre programme de parrainage ?',
        answer:
          'Les clients réguliers peuvent accéder à notre programme de parrainage et recevoir des réductions sur les convoyages traditionnels.',
      },
    ],
    partenaires: [
      {
        question: 'Qui sont vos principaux partenaires ?',
        answer:
          'Nous collaborons avec des entreprises telles que Generali, Aramis, Stellantis, CAT, GCA, EMILFREY, et Total.',
      },
      {
        question: 'Quels sont les avantages de collaborer avec ATE69 ?',
        answer:
          "Nos partenaires bénéficient de services de convoyage fiables, d'une gestion complète des livraisons, et d'un accès à des options personnalisées pour répondre à leurs besoins spécifiques.",
      },
      {
        question: 'Comment puis-je devenir partenaire de ATE69 ?',
        answer:
          'Les entreprises intéressées peuvent nous contacter pour discuter des opportunités de partenariat.',
      },
      {
        question:
          'Quels sont les avantages de votre plateforme de réservation ?',
        answer:
          'Notre plateforme de réservation est ergonomique, autonome, et permet de réserver un convoyage en moins de 30 secondes.',
      },
    ],
    aspectsLegaux: [
      {
        question: 'Quelle est la juridiction compétente en cas de litige ?',
        answer:
          'En cas de litige, les tribunaux compétents de Lyon sont désignés pour trancher, conformément aux lois françaises.',
      },
      {
        question: 'Comment gérez-vous les cas de force majeure ?',
        answer:
          'Les événements de force majeure, comme les catastrophes naturelles ou interruptions de transport, sont pris en compte et peuvent entraîner une reprogrammation ou annulation du service sans pénalité.',
      },
      {
        question: 'Quelle est votre politique de confidentialité ?',
        answer:
          'Nous respectons la confidentialité de vos données personnelles, conformément aux lois en vigueur. Consultez notre politique de confidentialité pour plus de détails.',
      },
      {
        question:
          "Comment puis-je m'assurer que mon véhicule est conforme avant le convoyage ?",
        answer:
          "Le client est responsable de s'assurer que le véhicule est conforme aux normes légales, y compris les équipements spécifiques requis.",
      },
      {
        question:
          'Quelle est votre politique en cas de contraventions pendant le convoyage ?',
        answer:
          "ATE69 assume la responsabilité des contraventions liées au stationnement ou à la circulation, sauf si elles résultent d'une non-conformité du véhicule fournie par le client.",
      },
    ],
  };

  return (
    <div>
      <Header />
      <div className="faq-container">
        <h1>F.A.Q</h1>
        <div className="faq-search">
          <input
            type="text"
            placeholder="Posez votre question..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <FAQSection title="Général" items={faqData.general} query={query} />
        <FAQSection
          title="Convoyage Occasionnel"
          items={faqData.convoyageOccasionnel}
          query={query}
        />
        <FAQSection
          title="Convoyage Régulier"
          items={faqData.convoyageRegulier}
          query={query}
        />
        <FAQSection
          title="Processus de Convoyage"
          items={faqData.processusConvoyage}
          query={query}
        />
        <FAQSection
          title="Conditions Générales de Vente"
          items={faqData.conditionsVente}
          query={query}
        />
        <FAQSection
          title="Support Client"
          items={faqData.supportClient}
          query={query}
        />
        <FAQSection
          title="Partenaires et Collaborations"
          items={faqData.partenaires}
          query={query}
        />
        <FAQSection
          title="Aspects Légaux et Sécurité"
          items={faqData.aspectsLegaux}
          query={query}
        />
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
