// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/register-c.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-pro {
  position: relative;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  color: #4a4a4a; /* Gris foncé pour le texte */
}

.header-pro::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #31BAF0;
  opacity: 0.8;
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  color: #fff; /* Gris foncé pour tous les textes */
}

.card-custom {
  background-color: #f8f9fa; /* Gris très léger */
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Ombre légère */
  padding: 20px;
  text-align: center;
    color: #4a4a4a; /* Gris foncé pour tous les textes */

}
.card-icon {
  font-size: 2rem;
  color: #31BAF0;
}

`, "",{"version":3,"sources":["webpack://./src/css/HeaderC.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yDAA+C;EAC/C,sBAAsB;EACtB,2BAA2B;EAC3B,cAAc,EAAE,6BAA6B;AAC/C;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW,EAAE,oCAAoC;AACnD;;AAEA;EACE,yBAAyB,EAAE,oBAAoB;EAC/C,YAAY;EACZ,kBAAkB;EAClB,2CAA2C,EAAE,iBAAiB;EAC9D,aAAa;EACb,kBAAkB;IAChB,cAAc,EAAE,oCAAoC;;AAExD;AACA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".header-pro {\r\n  position: relative;\r\n  background-image: url('../img/register-c.webp');\r\n  background-size: cover;\r\n  background-position: center;\r\n  color: #4a4a4a; /* Gris foncé pour le texte */\r\n}\r\n\r\n.header-pro::before {\r\n  content: '';\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: #31BAF0;\r\n  opacity: 0.8;\r\n  z-index: 1;\r\n}\r\n\r\n.header-content {\r\n  position: relative;\r\n  z-index: 2;\r\n  color: #fff; /* Gris foncé pour tous les textes */\r\n}\r\n\r\n.card-custom {\r\n  background-color: #f8f9fa; /* Gris très léger */\r\n  border: none;\r\n  border-radius: 8px;\r\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Ombre légère */\r\n  padding: 20px;\r\n  text-align: center;\r\n    color: #4a4a4a; /* Gris foncé pour tous les textes */\r\n\r\n}\r\n.card-icon {\r\n  font-size: 2rem;\r\n  color: #31BAF0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
