import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import '../css/LegalPage.css';

const CGV = () => {
  return (
    <div>
      <Helmet>
        <title>Conditions Générales de Vente - ATE69</title>
        <meta
          name="description"
          content="Découvrez les Conditions Générales de Vente applicables aux services d'ATE69 Auto Transport Express."
        />
      </Helmet>

      <Header />

      <div className="cgv-container">
        <h1>Conditions Générales de Vente</h1>
        <p>Applicables à compter du 30 Août 2023</p>

        {/* Sommaire */}
        <div className="cgv-summary">
          <h2>Sommaire</h2>
          <ul>
            <li>
              <a href="#prea">Préambule</a>
            </li>
            <li>
              <a href="#clause1">Clause n° 1 : Objet et Champ d’Application</a>
            </li>
            <li>
              <a href="#clause2">Clause n° 2 : Prix</a>
            </li>
            <li>
              <a href="#clause2bis">
                Clause n° 2bis : Types de Véhicules Non Éligibles
              </a>
            </li>
            <li>
              <a href="#clause3">Clause n° 3 : Conditions de Paiement</a>
            </li>
            <li>
              <a href="#clause4">
                Clause n° 4 : Enlèvement, Livraison et Inspection
              </a>
            </li>
            <li>
              <a href="#clause5">
                Clause n° 5 : Réserves et Procédure en Cas de Dommage
              </a>
            </li>
            <li>
              <a href="#clause6">Clause n° 6 : Responsabilité</a>
            </li>
            <li>
              <a href="#clause7">
                Clause n° 7 : Réclamations et Gestion des Litiges
              </a>
            </li>
            <li>
              <a href="#clause8">Clause n° 8 : Juridiction Compétente</a>
            </li>
            <li>
              <a href="#clause9">Clause n° 9 : Annulation & Pénalités</a>
            </li>
            <li>
              <a href="#clause10">
                Clause n° 10 : Conformité Légale et Responsabilité des
                Contraventions
              </a>
            </li>
            <li>
              <a href="#clause11">
                Clause n° 11 : Modifications des Conditions de Service
              </a>
            </li>
            <li>
              <a href="#clause12">
                Clause n° 12 : Force Majeure, Exceptions Opérationnelles, et
                Détournements
              </a>
            </li>
          </ul>
        </div>

        {/* Préambule */}
        <h2 id="prea">Préambule</h2>
        <p>
          ATE69 Auto Transport Express, ci-après désignée "la Société", est
          spécialisée dans le service de convoyage de véhicules. Ces Conditions
          Générales de Vente (CGV) sont destinées à définir les termes et
          conditions sous lesquels ces services sont proposés à nos clients.
        </p>

        {/* Clauses */}
        <h2 id="clause1">Clause n° 1 : Objet et Champ d’Application</h2>
        <p>
          Ces CGV régissent les modalités de vente des services de convoyage de
          véhicules par la Société à ses clients. Elles précisent les conditions
          de commande, de paiement, de livraison et de gestion des services
          proposés par la Société. Tout engagement avec la Société implique
          l'adhésion sans réserve aux présentes CGV, sauf conditions
          particulières ajoutées par écrit et signées par les deux parties.
        </p>

        <h2 id="clause2">Clause n° 2 : Prix</h2>
        <p>
          ATE69 Auto Transport Express propose des services de convoyage avec
          une tarification transparente et ajustée aux besoins spécifiques de
          chaque client. Les prix sont indiqués en euros et sont exempts de TVA,
          conformément au régime fiscal des auto-entrepreneurs. Les tarifs
          peuvent varier en fonction de différents paramètres, notamment le type
          de véhicule et les conditions de trajet.
        </p>
        <p>
          <strong>Détails de la tarification :</strong>
          <ul>
            <li>
              <strong>Prestation de base :</strong> Le prix de la prestation
              comprend le transport du véhicule du point A au point B. Il est
              calculé en fonction de la distance à parcourir et du type de
              véhicule. Pour assurer une tarification précise, veuillez fournir
              des informations exactes sur le type de véhicule (citadine,
              berline, utilitaire) lors de la commande.
            </li>
            <li>
              <strong>Type de véhicule et consommation de carburant :</strong>{' '}
              Les estimations de consommation de carburant sont ajustées en
              fonction du type de véhicule transporté. Par exemple, les
              utilitaires peuvent avoir une consommation différente des
              citadines ou des berlines. Veuillez spécifier correctement le type
              de véhicule pour obtenir une tarification précise.
            </li>
            <li>
              <strong>Frais de route :</strong> Les frais associés au trajet,
              tels que les péages et le carburant, sont estimés en fonction de
              la classe de péage appropriée pour le type de véhicule transporté.
              Pour les utilitaires de classe 2 ou 3, les frais peuvent différer
              de ceux des véhicules légers. Ces frais sont inclus dans le devis
              initial mais peuvent être ajustés en fonction des conditions
              réelles rencontrées sur la route.
            </li>
          </ul>
        </p>
        <p>
          <strong>Gestion des ajustements de coûts :</strong>
          <ul>
            <li>
              <strong>Communication transparente :</strong> Toute variation
              significative dans les coûts estimés de péages, de carburant ou
              tout autre frais imprévu sera communiquée au client. Cette
              communication permet d'ajuster le montant final en conséquence, en
              fonction des conditions réelles rencontrées lors du trajet.
            </li>
            <li>
              <strong>Facturation en nom du client :</strong> Le client accepte
              que les tickets de péage, le carburant et tous les débours liés au
              convoyage soient facturés en son nom. ATE69 Auto Transport Express
              s'engage à fournir une gestion transparente et des justificatifs
              pour ces frais, assurant ainsi que toutes les dépenses sont
              clairement documentées et validées.
            </li>
          </ul>
        </p>

        <h2 id="clause2bis">
          Clause n° 2bis : Types de Véhicules Non Éligibles
        </h2>
        <p>
          ATE69 Auto Transport Express se spécialise dans le transport de types
          de véhicules qui répondent à des critères spécifiques de taille, de
          poids et d'état pour garantir un service sécurisé et efficace.
          Cependant, certains types de véhicules ne sont pas éligibles pour nos
          services en raison de leurs particularités qui dépassent les capacités
          opérationnelles ou les réglementations standard de la Société.
        </p>
        <p>
          <strong>
            Les véhicules non éligibles incluent, mais ne sont pas limités à :
          </strong>
          <ul>
            <li>Véhicules de plus de 3,5 tonnes.</li>
            <li>
              Véhicules avec des dimensions qui excèdent les limites légales
              sans permis spécial.
            </li>
            <li>
              Véhicules endommagés nécessitant une remorque pour le transport.
            </li>
            <li>
              Véhicules classés comme dangereux en raison de fuites de liquides
              inflammables ou toxiques.
            </li>
            <li>
              Véhicules de collection ou de valeur extrême nécessitant des
              arrangements de transport spécialisés non couverts par notre
              assurance standard.
            </li>
            <li>
              Véhicules non immatriculés ou sans documentation légale appropriée
              pour le transport sur route.
            </li>
            <li>
              Véhicules sans permis, y compris les micro-voitures qui ne
              répondent pas aux normes de transport routier.
            </li>
            <li>
              Deux et trois roues, incluant les motocyclettes, scooters et
              tricycles motorisés.
            </li>
            <li>
              Caravanes et véhicules récréatifs de dimensions non standard.
            </li>
            <li>
              Sidecars et autres véhicules modifiés avec des configurations non
              standard.
            </li>
            <li>
              Véhicules non homologués pour la route ou ceux transformés de
              manière à enfreindre les régulations de sécurité routière.
            </li>
          </ul>
        </p>
        <p>
          <strong>Exceptions :</strong> Des arrangements spéciaux peuvent
          parfois être organisés pour les véhicules ne répondant pas aux
          critères standards, à condition que des mesures de précaution
          supplémentaires et des ajustements tarifaires soient mis en place à
          l'avance avec l'accord écrit du client.
        </p>

        <h2 id="clause3">Clause n° 3 : Conditions de Paiement</h2>
        <p>
          Les services fournis par ATE69 Auto Transport Express doivent être
          payés conformément aux conditions suivantes, qui varient selon le
          statut du client et la nature de la commande :
        </p>
        <ul>
          <li>
            <strong>Nouveaux clients :</strong> Un acompte de 50% du montant
            total estimé est requis au moment de la commande pour confirmer et
            planifier le service de convoyage. Le solde est dû à la livraison du
            véhicule.
          </li>
          <li>
            <strong>Clients occasionnels :</strong> Un acompte de 30% est exigé
            pour les clients ayant déjà bénéficié de nos services mais n'ayant
            pas encore atteint le statut de grand compte.
          </li>
          <li>
            <strong>Grands comptes :</strong> Des conditions de paiement
            personnalisées et négociées sont appliquées, pouvant inclure des
            modalités de paiement étendues ou des conditions particulières
            basées sur le volume et la fréquence des services sollicités.
          </li>
        </ul>
        <p>
          <strong>Détails pour le paiement par virement bancaire :</strong>
        </p>
        <ul>
          <li>IBAN : FR7616598000014000019361461</li>
          <li>BIC : FPELFR21XXX</li>
        </ul>
        <p>
          Le paiement doit être réalisé par virement bancaire avant le début du
          service selon les pourcentages d'acompte indiqués, sauf accord
          spécifique stipulé dans un contrat écrit. En cas de retard de
          paiement, des intérêts de retard pourront être appliqués conformément
          aux taux légaux en vigueur.
        </p>
        <p>
          <strong>Facturation :</strong>
        </p>
        <ul>
          <li>
            Une facture détaillée sera fournie à chaque client après l'exécution
            du service, précisant le montant total dû, les acomptes déjà versés,
            et le solde restant éventuel.
          </li>
          <li>
            Il est de la responsabilité du client de s'assurer que les
            informations de paiement fournies à ATE69 Auto Transport Express
            sont correctes et complètes.
          </li>
        </ul>
        <p>
          <strong>Retard de paiement :</strong>
        </p>
        <ul>
          <li>
            En cas de non-paiement à l'échéance, ATE69 Auto Transport Express se
            réserve le droit de suspendre les services en cours et de refuser
            toute nouvelle commande jusqu'au règlement des montants dus.
          </li>
          <li>
            Un rappel sera envoyé au client après la date d'échéance. Si le
            paiement n'est pas reçu dans les 15 jours suivant ce rappel, des
            frais de recouvrement pourront être imposés.
          </li>
        </ul>

        <h2 id="clause4">Clause n° 4 : Enlèvement, Livraison et Inspection</h2>
        <p>
          ATE69 Auto Transport Express assure le service de convoyage de
          véhicules, ce qui inclut les étapes d'enlèvement et de livraison du
          véhicule selon les modalités convenues avec le client. Les détails de
          ces procédures sont essentiels pour garantir la transparence et la
          sécurité de chaque opération.
        </p>
        <p>
          <strong>Enlèvement :</strong>
        </p>
        <ul>
          <li>
            <strong>État des Lieux :</strong> Un état des lieux complet du
            véhicule est réalisé au moment de l'enlèvement. Ce processus
            comprend la prise d'au moins quinze (15) photos détaillées du
            véhicule pour documenter son état avant le transport.
          </li>
          <li>
            <strong>Documentation :</strong> Le convoyeur remplit un carnet de
            CMR (Lettre de voiture internationale), où sont notées toutes
            observations pertinentes concernant l'état du véhicule, y compris
            les éventuels dommages ou manquements (rayures, enfoncements,
            éléments manquants ou cassés). Des schémas de voiture sont utilisés
            pour indiquer précisément les dommages avec des codes spécifiques (R
            pour rayé, E pour enfoncé, M pour manquant, C pour cassé).
          </li>
          <li>
            <strong>Validation de l'État :</strong> Les réserves prises lors de
            l'enlèvement doivent être clairement écrites et accompagnées des
            photos correspondantes. Ces documents sont les seuls valides pour
            référence en cas de réclamation ultérieure concernant l'état du
            véhicule.
          </li>
        </ul>
        <p>
          <strong>Livraison :</strong>
        </p>
        <ul>
          <li>
            <strong>Réception par le Destinataire :</strong> La livraison
            s'effectue à l'adresse convenue et selon les délais établis lors de
            la commande. Un nouvel état des lieux est réalisé en présence du
            destinataire pour confirmer le maintien de l'état du véhicule
            conformément à l'enlèvement documenté.
          </li>
          <li>
            <strong>Confirmation de Réception :</strong> Le destinataire est
            tenu de signer le carnet de CMR confirmant la réception du véhicule
            et, si nécessaire, de noter toute réserve concernant l'état du
            véhicule à ce moment-là, avec les mêmes procédures de documentation
            que lors de l'enlèvement.
          </li>
        </ul>
        <p>
          <strong>Transfert des Risques :</strong> Les risques liés au transport
          du véhicule sont à la charge de la Société jusqu'à ce que la livraison
          soit complète et que le destinataire ait confirmé la réception du
          véhicule en bon état, sauf indication contraire notée dans le CMR à la
          livraison.
        </p>

        <h2 id="clause5">
          Clause n° 5 : Réserves et Procédure en Cas de Dommage
        </h2>
        <p>
          ATE69 Auto Transport Express s'engage à transporter les véhicules de
          manière sécurisée. Cependant, en cas de dommage survenu pendant le
          transport, des mesures précises sont mises en place pour assurer une
          gestion efficace et transparente des réserves :
        </p>
        <ul>
          <li>
            <strong>Acceptation Obligatoire du Véhicule :</strong> Le
            destinataire est tenu d'accepter la livraison du véhicule, quel que
            soit son état, sauf en cas de force majeure ou lorsque la livraison
            est tentée en dehors des heures d'ouverture. Dans un tel cas, le
            convoyeur reviendra au plus tôt durant les heures d'ouverture
            suivantes pour compléter la livraison.
          </li>
          <li>
            En aucun cas, le destinataire ne peut refuser la livraison sur la
            base de dommages subis pendant le transport.
          </li>
        </ul>
        <p>
          <strong>Procédure de Réserve :</strong>
        </p>
        <ul>
          <li>
            Lors de chaque enlèvement et livraison, un état des lieux est
            effectué et un exemplaire du carnet de transport (CMR) est laissé à
            la personne présente, qui doit noter toutes réserves claires,
            précises, datées et signées concernant l'état du véhicule.
          </li>
          <li>
            Toute réserve doit être communiquée à ATE69 Auto Transport Express
            dans les 48 heures ouvrables suivant la livraison, par lettre
            recommandée avec accusé de réception.
          </li>
        </ul>
        <p>
          <strong>Documentation Requise pour les Réclamations :</strong>
        </p>
        <ul>
          <li>Copie du contrat de convoyage (CMR)</li>
          <li>Photos claires des dommages</li>
          <li>Liste détaillée des réserves</li>
          <li>Devis pour les réparations, le cas échéant</li>
        </ul>
        <p>
          <strong>Évaluation des Réclamations :</strong>
        </p>
        <ul>
          <li>
            Le service d'exploitation ouvrira un dossier de litige et examinera
            toutes les pièces fournies. Une comparaison des photos prises lors
            de l'enlèvement et de la livraison sera effectuée pour déterminer si
            les dommages étaient préexistants.
          </li>
          <li>
            Si les dommages étaient présents lors de l'enlèvement, aucune
            responsabilité ne sera attribuée à ATE69 Auto Transport Express.
          </li>
          <li>
            Dans le cas où de nouveaux dommages sont constatés, des solutions
            appropriées seront recherchées, pouvant inclure une prise en charge
            par ATE69 Auto Transport Express ou une déclaration à notre
            assurance.
          </li>
        </ul>
        <p>
          <strong>Exceptions :</strong> Les impacts vitraux et dommages aux
          pneumatiques ne sont généralement pas considérés comme des réserves
          valables, car ils sont souvent indépendants de la volonté de la
          société et de la qualité de la conduite.
        </p>

        <h2 id="clause6">Clause n° 6 : Responsabilité</h2>
        <p>
          ATE69 Auto Transport Express s'engage à fournir ses services de
          convoyage de manière professionnelle. La Société est couverte par une
          assurance en tout risque qui protège les véhicules convoyés jusqu'à
          une hauteur de 80 000 €. Cette couverture est en place pour sécuriser
          et garantir la qualité et la fiabilité de nos services.
        </p>
        <p>
          <strong>Limitation de Responsabilité :</strong>
        </p>
        <ul>
          <li>
            <strong>Responsabilité Directe :</strong> La responsabilité de la
            Société est limitée aux dommages directs au véhicule qui sont
            prouvés et résultent exclusivement de notre négligence ou faute
            manifeste lors de l'exécution du service de convoyage.
          </li>
          <li>
            <strong>Plafonnement :</strong> En aucun cas, la responsabilité de
            la Société pour les dommages subis ne dépassera le montant de la
            couverture d'assurance, soit 80 000 €.
          </li>
        </ul>
        <p>
          <strong>Exclusions de Responsabilité :</strong>
        </p>
        <ul>
          <li>
            <strong>Dommages Indirects :</strong> La Société ne peut être tenue
            responsable des dommages indirects, incluant la perte d'usage du
            véhicule, la perte de profits ou les perturbations commerciales,
            indépendamment de leur prévisibilité.
          </li>
          <li>
            <strong>Cas de Force Majeure et Exceptions :</strong> Comme stipulé
            dans la Clause n° 12, la Société n'est pas responsable des
            manquements à l'exécution de ses obligations en cas de force majeure
            ou de circonstances extérieures imprévisibles qui empêchent ou
            retardent la prestation des services.
          </li>
          <li>
            <strong>Fautes du Client ou Tiers :</strong> Les dommages résultant
            des actions, négligences, instructions incorrectes, ou informations
            incomplètes fournies par le client ou par des tiers ne sont pas de
            la responsabilité de la Société.
          </li>
        </ul>
        <p>
          <strong>Procédure en Cas de Réclamation :</strong>
        </p>
        <ul>
          <li>
            Toutes les réclamations pour dommages doivent être faites en accord
            avec la procédure établie dans la Clause n° 5, nécessitant des
            preuves documentées et la notification à la Société dans les délais
            prescrits.
          </li>
          <li>
            Notre service d'exploitation examinera chaque réclamation avec soin
            pour déterminer la responsabilité et engager les mesures appropriées
            pour résoudre le problème de manière équitable.
          </li>
        </ul>

        <h2 id="clause7">Clause n° 7 : Réclamations et Gestion des Litiges</h2>
        <p>
          Toute réclamation concernant une non-conformité ou des éléments
          manquants à la livraison doit être formulée par écrit dans les cinq
          jours ouvrables suivant la réception du véhicule. Le client est invité
          à fournir toutes les preuves nécessaires, telles que des photos et une
          description détaillée des problèmes rencontrés, en utilisant les
          formulaires et procédures définis dans la Clause n° 5.
        </p>
        <p>
          Les réclamations sont traitées par notre service d'exploitation qui
          est responsable de l'ouverture et du suivi des dossiers de litige. Une
          réponse formelle est fournie dans un délai de quinze jours après
          réception de la réclamation complète. En cas de dommages confirmés qui
          n'étaient pas présents lors de l'enlèvement, et non dus à des cas de
          force majeure, la société s'engage à trouver une résolution équitable,
          pouvant inclure la réparation, le remplacement, ou une compensation
          financière, selon les termes de notre police d'assurance et dans le
          respect des limites de responsabilité établies.
        </p>

        <h2 id="clause8">Clause n° 8 : Juridiction Compétente</h2>
        <p>
          En cas de litige relatif à l'interprétation, l'exécution ou la
          validité de ces CGV, et à défaut d'un accord amiable entre les
          parties, le litige sera soumis aux tribunaux compétents de Lyon. Cette
          attribution de juridiction s'applique quelle que soit la localisation
          du client, le mode de paiement utilisé, ou le lieu de livraison, et ce
          même en cas de pluralité de défendeurs ou d'appel en garantie.
        </p>
        <p>
          Les parties conviennent que tout litige ou différend qui ne peut être
          résolu à l'amiable sera régi par le droit français, garantissant ainsi
          une procédure judiciaire claire et prévisible.
        </p>

        <h2 id="clause9">Clause n° 9 : Annulation & Pénalités</h2>
        <p>
          En cas d'annulation de la commande par le client, les pénalités
          suivantes s'appliqueront :
        </p>
        <ul>
          <li>
            <strong>
              Annulation moins de 24 heures avant le service prévu :
            </strong>{' '}
            100% du prix du service est facturé.
          </li>
          <li>
            <strong>Annulation entre 48 et 25 heures avant le service :</strong>{' '}
            50% du prix du service est facturé.
          </li>
          <li>
            <strong>Annulation plus de 48 heures avant le service :</strong>{' '}
            aucune pénalité n’est appliquée, avec la possibilité de reprogrammer
            le service une fois sans frais supplémentaires.
          </li>
        </ul>
        <p>
          Ces pénalités compensent les préparatifs déjà engagés et les
          ressources allouées.
        </p>

        <h2 id="clause10">
          Clause n° 10 : Conformité Légale et Responsabilité des Contraventions
        </h2>
        <p>
          La responsabilité de s'assurer que le véhicule est conforme aux normes
          et réglementations en vigueur, y compris les équipements spécifiques
          requis (tels que ceux demandés par la loi montagne), incombe
          entièrement au client. En cas de contravention due à un défaut
          d'équipement ou de conformité du véhicule pendant la période de
          convoyage, le client est tenu responsable des amendes ou pénalités :
        </p>
        <ul>
          <li>
            <strong>
              Les contraventions pour stationnement ou circulation durant le
              convoyage
            </strong>{' '}
            sont à la charge d'ATE69, à moins que le problème ne résulte d'un
            manquement du client à fournir un véhicule conforme.
          </li>
          <li>
            <strong>
              En cas de contravention issue de radars automatiques pour excès de
              vitesse,
            </strong>{' '}
            le client est responsable de dénoncer ATE69 comme étant le
            conducteur au moment de l'infraction, afin de procéder à la gestion
            appropriée des points et des amendes.
          </li>
        </ul>

        <h2 id="clause11">
          Clause n° 11 : Modifications des Conditions de Service
        </h2>
        <p>
          ATE69 Auto Transport Express se réserve le droit de modifier les
          termes des présentes CGV à tout moment. Toute modification
          substantielle sera communiquée au client avant son application, et le
          client aura la possibilité de résilier le contrat sans pénalité si les
          nouvelles conditions ne sont pas acceptables.
        </p>

        <h2 id="clause12">
          Clause n° 12 : Force Majeure, Exceptions Opérationnelles, et
          Détournements
        </h2>
        <p>
          ATE69 Auto Transport Express s'engage à exécuter tous les services de
          convoyage avec fiabilité et sécurité. Néanmoins, nous reconnaissons
          que certaines situations indépendantes de notre volonté peuvent
          influencer ou entraver notre capacité à remplir nos obligations
          contractuelles. Ces situations sont classées en trois catégories
          principales :
        </p>
        <p>
          <strong>1. Événements de Force Majeure</strong>
        </p>
        <p>
          Considérés comme tels sont les événements extérieurs, imprévisibles,
          et irrésistibles, définis conformément à l'article 1218 du Code civil,
          y compris mais non limités à :
        </p>
        <ul>
          <li>Catastrophes naturelles</li>
          <li>Actes de terrorisme</li>
          <li>Émeutes civiles</li>
          <li>Interruptions significatives des infrastructures de transport</li>
          <li>Mesures gouvernementales ou légales impératives</li>
        </ul>
        <p>
          <strong>2. Exceptions Opérationnelles Spécifiques</strong>
        </p>
        <p>
          Ces exceptions couvrent des situations particulières pouvant affecter
          la prestation de services :
        </p>
        <ul>
          <li>
            Événements Publics Majeurs (ex. : Jeux Olympiques, manifestations
            d'envergure) pouvant impacter significativement l'accès aux routes
            ou les conditions de circulation.
          </li>
          <li>
            Conditions Météorologiques Extrêmes (conformément à la loi montagne)
            telles que fortes chutes de neige, tempêtes, ou inondations.
          </li>
          <li>
            Pics de Circulation associés à des périodes de forte affluence
            routière, comme les départs en vacances.
          </li>
          <li>
            Autres Interruptions incluant les interruptions inattendues des
            services publics ou tout autre événement local spécifique.
          </li>
        </ul>
        <p>
          <strong>3. Détournements et Kilomètres Supplémentaires</strong>
        </p>
        <p>
          Face à des événements imprévus nécessitant un détournement du trajet
          initialement prévu (fermetures d'autoroutes, accidents majeurs, etc.),
          entraînant ainsi des kilomètres supplémentaires :
        </p>
        <ul>
          <li>
            <strong>Surfacturation des Kilomètres Supplémentaires :</strong> Le
            client sera informé promptement d'un ajustement tarifaire basé sur
            le tarif au kilomètre établi pour couvrir ces kilomètres
            additionnels.
          </li>
          <li>
            <strong>Droit de Recours du Client :</strong> ATE69 s'engage à
            fournir toutes les informations et documentations permettant au
            client de rechercher une compensation auprès des entités
            responsables du détournement.
          </li>
        </ul>
        <p>
          <strong>Notification et Gestion :</strong> ATE69 Auto Transport
          Express s'engage à notifier le client dès que possible en cas
          d'événement de force majeure, d'exception opérationnelle spécifique,
          ou de détournement nécessaire. Nous discuterons des options
          disponibles, y compris le report du service, sa reprogrammation, ou,
          en dernier recours, son annulation sans pénalité pour les parties
          concernées.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default CGV;
