// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-section {
  background: #f8f9fa;
}

.notice-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.info-block {
  margin-bottom: 20px;
}

.notice-content h3 {
  color: #6c757d;
}

.notice-content p {
  color: #4a4a4a;
}
`, "",{"version":3,"sources":["webpack://./src/css/Notice.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".notice-section {\r\n  background: #f8f9fa;\r\n}\r\n\r\n.notice-content {\r\n  background-color: #ffffff;\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.info-block {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.notice-content h3 {\r\n  color: #6c757d;\r\n}\r\n\r\n.notice-content p {\r\n  color: #4a4a4a;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
