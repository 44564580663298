import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Card, Spinner, Container, Row, Col, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function AdminFinancial() {
  const [clients, setClients] = useState([]);
  const [convoyages, setConvoyages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isSubscribed = true; // Ensure cleanup to avoid memory leaks

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const [clientsResponse, convoyagesResponse] = await Promise.all([
          axios.get('https://ate69.fr:5001/api/clients', { headers }),
          axios.get('https://ate69.fr:5003/api/convoyages', { headers }),
        ]);

        if (isSubscribed) {
          setClients(clientsResponse.data);
          setConvoyages(convoyagesResponse.data);
        }
      } catch (error) {
        setError('Erreur lors de la récupération des données.');
        console.error('Error fetching clients and convoyages data:', error);
      } finally {
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false; // Cleanup when component unmounts
    };
  }, []);

  if (isLoading)
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );

  if (error) return <Alert variant="danger">{error}</Alert>;

  // Bloc 1: Clients avec convoyages proches, triés par availabilityDate
  const clientsWithConvoyages = clients
    .filter((client) =>
      convoyages.some(
        (conv) => conv.clientDetails.customIdC === client.customIdC
      )
    )
    .sort(
      (a, b) => new Date(a.availabilityDate) - new Date(b.availabilityDate)
    );

  // Bloc 2: Clients avec convoyages `terminated` ou `finished`, triés par completedAt
  const completedClients = convoyages
    .filter(
      (conv) => conv.status === 'terminated' || conv.status === 'finished'
    )
    .sort((a, b) => new Date(a.completedAt) - new Date(b.completedAt));

  // Bloc 3: Clients ayant dépassé leur encours max
  const overEncoursClients = clients.filter((client) =>
    convoyages.some(
      (conv) =>
        conv.clientDetails.customIdC === client.customIdC &&
        client.financialInfo.encoursMax < conv.pricingDetails.totalPrice
    )
  );

  // Bloc 4: Clients approuvés sans convoyages
  const approvedWithoutConvoyages = clients.filter(
    (client) =>
      client.status === 'approved' &&
      !convoyages.some(
        (conv) => conv.clientDetails.customIdC === client.customIdC
      )
  );

  return (
    <Container className="mt-4">
      <h1>Tableau de Bord Financier</h1>

      {/* Bloc 1: Clients avec convoyages proches */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Clients avec convoyages proches</h4>
        </Card.Header>
        <Card.Body>
          {clientsWithConvoyages.length > 0 ? (
            clientsWithConvoyages.map((client) => (
              <div key={client.customIdC} className="mb-3">
                <p>
                  <strong>
                    {client.companyName} ({client.customIdC})
                  </strong>
                </p>
                <p>
                  <strong>Disponibilité:</strong>{' '}
                  {new Date(client.availabilityDate).toLocaleDateString()}
                </p>
                <Link
                  to={`/admin/financial-details/${client.customIdC}`}
                  className="btn btn-primary"
                >
                  Voir les détails
                </Link>
              </div>
            ))
          ) : (
            <p>Aucun client trouvé.</p>
          )}
        </Card.Body>
      </Card>

      {/* Bloc 2: Clients avec convoyages terminés ou finalisés */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Clients avec convoyages terminés ou finalisés</h4>
        </Card.Header>
        <Card.Body>
          {completedClients.length > 0 ? (
            completedClients.map((conv) => (
              <div key={conv.customIdF} className="mb-3">
                <p>
                  <strong>Convoyage {conv.customIdF}</strong>
                </p>
                <p>
                  <strong>Client ID:</strong> {conv.clientDetails.customIdC}
                </p>
                <p>
                  <strong>Terminé le:</strong>{' '}
                  {new Date(conv.completedAt).toLocaleDateString()}
                </p>
                <Link
                  to={`/admin/financial-details/${conv.clientDetails.customIdC}`}
                  className="btn btn-primary"
                >
                  Voir les détails
                </Link>
              </div>
            ))
          ) : (
            <p>Aucun convoyage terminé ou finalisé trouvé.</p>
          )}
        </Card.Body>
      </Card>

      {/* Bloc 3: Clients ayant dépassé l'encours max */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Clients ayant dépassé l'encours max</h4>
        </Card.Header>
        <Card.Body>
          {overEncoursClients.length > 0 ? (
            overEncoursClients.map((client) => (
              <div key={client.customIdC} className="mb-3">
                <p>
                  <strong>
                    {client.companyName} ({client.customIdC})
                  </strong>
                </p>
                <p>
                  <strong>Encours max:</strong>{' '}
                  {client.financialInfo.encoursMax} €
                </p>
                <Link
                  to={`/admin/financial-details/${client.customIdC}`}
                  className="btn btn-primary"
                >
                  Voir les détails
                </Link>
              </div>
            ))
          ) : (
            <p>Aucun client en dépassement d'encours trouvé.</p>
          )}
        </Card.Body>
      </Card>

      {/* Bloc 4: Clients approuvés sans convoyages */}
      <Card className="mb-4">
        <Card.Header>
          <h4>Clients approuvés sans convoyages</h4>
        </Card.Header>
        <Card.Body>
          {approvedWithoutConvoyages.length > 0 ? (
            approvedWithoutConvoyages.map((client) => (
              <div key={client.customIdC} className="mb-3">
                <p>
                  <strong>
                    {client.companyName} ({client.customIdC})
                  </strong>
                </p>
                <Link
                  to={`/admin/financial-details/${client.customIdC}`}
                  className="btn btn-primary"
                >
                  Voir les détails
                </Link>
              </div>
            ))
          ) : (
            <p>Aucun client approuvé sans convoyage trouvé.</p>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AdminFinancial;
