import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormCard from '../ClientForms/FormCard';

function AdminForms() {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    setLoading(true);
    setError(''); // Réinitialiser l'erreur à chaque appel
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error(
          "Aucun token d'authentification trouvé. Veuillez vous connecter."
        );
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const statuses = [
        'pending',
        'validated',
        'rejected',
        'started',
        'terminated',
        'finished',
      ];
      let allForms = [];

      for (const status of statuses) {
        const response = await axios.get(
          `https://ate69.fr:5003/api/convoyages/${status}`,
          config
        );
        allForms = [...allForms, ...response.data];
      }

      setForms(allForms);
    } catch (error) {
      setError(
        'Une erreur est survenue lors de la récupération des formulaires. ' +
          error.message
      );
      console.error('Erreur lors de la récupération des formulaires:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur: {error}</div>;

  return (
    <div>
      <h1>Gestion des Formulaires</h1>
      {[
        'pending',
        'validated',
        'rejected',
        'started',
        'terminated',
        'finished',
      ].map((status) => (
        <div key={status} className="mb-4">
          <h2>
            Formulaires {status.charAt(0).toUpperCase() + status.slice(1)}
          </h2>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {forms
              .filter((form) => form.status === status)
              .map((form) => (
                <div key={form._id} className="mb-3">
                  <FormCard form={form} onFormUpdate={fetchForms} />
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AdminForms;
