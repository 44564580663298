import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import '../css/Franchise.css';

const Header = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    department: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Envoyer les informations par email via une API, ou notifier
    console.log('Form data submitted:', formData);
    alert('Votre pré-inscription a bien été envoyée pour 2025 !');
  };

  return (
    <>
      <Helmet>
        <title>Devenez franchisé avec ATEGROUP – Pré-inscriptions 2025</title>
        <meta name="description" content="Pré-inscrivez-vous pour rejoindre le réseau de franchises ATEGROUP en 2025 et profitez d’une gestion automatisée et de nombreux avantages." />
      </Helmet>

      <header className="header_f">
        <div className="container text-center py-5">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: 'spring', stiffness: 120, delay: 0.2 }}
          >
            <h1 className="display-4">Devenez franchisé avec ATEGROUP</h1>
            <h2 className="h4">Pré-inscriptions ouvertes pour 2025</h2>
            <p className="lead">Rejoignez un réseau innovant et bénéficiez de notre support exclusif.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: 'spring', stiffness: 120, delay: 0.4 }}
            className="form-container mt-5"
          >
            <h3 className="mb-4">Pré-inscription Franchise</h3>
            <form className="form-inline" onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Prénom"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <FontAwesomeIcon icon={faPhone} className="me-2" />
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Téléphone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                <select
                  className="form-control"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  required
                >
                  <option value="">Choisissez le département</option>
                  <option value="01">Ain (01)</option>
                  <option value="03">Allier (03)</option>
                  <option value="07">Ardèche (07)</option>
                  <option value="15">Cantal (15)</option>
                  <option value="26">Drôme (26)</option>
                  <option value="38">Isère (38)</option>
                  <option value="42">Loire (42)</option>
                  <option value="43">Haute-Loire (43)</option>
                  <option value="63">Puy-de-Dôme (63)</option>
                  <option value="69">Rhône (69)</option>
                  <option value="73">Savoie (73)</option>
                  <option value="74">Haute-Savoie (74)</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                Envoyer ma pré-inscription
              </button>
            </form>
          </motion.div>
        </div>
      </header>
    </>
  );
};

export default Header;
